// *******************************************************
// Table Desktop - _components/_ui/_Table/_TableDesktop.js
// -------------------------------------------------------
// This is the desktop Table
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
import * as Enums from '../../_enums';
import { Pagination } from '../../_state/_slices/_pagination';
import { Button } from './index';
import { UI } from '..';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  columns: string[],
  rows: any,
  actions?: any[],
  rowOnClick?: (row: any) => void
  pagination?: {
    data: Pagination,
    update: (pagingDirection: "next" | "previous") => void;
},
}
// --------------------------------

// *******************************************
// Component Implementation
// -------------------------------------------
export const Table = (props: Props) => {

    const _renderColumns = () => {
        const { columns, actions } = props;
        const _renderHeading = (item: any, index: number) => {
            return (
                <div key={`col-${index}`} className={[
                    S.Layout.container,
                    S.Padding._2,
                    S.Padding.Left_6].join(" ")}>
                    <div className={[S.Fonts.Montserrat_Bold, S.Padding.Top_10, S.Padding.Bottom_10, S.Fonts.Size_12, S.Fonts.Colour_whiteTertiary].join(" ")}>
                        {item}
                    </div>
                </div>
            );
        }
        const elements = columns.map(_renderHeading);
        return (
            <div className={[S.Layout.flexRow, S.Background.blueTertiary, S.Border.Radius_5].join(" ")}>
                {elements}
                {(actions) ? _renderHeading("", (elements.length + 1)) : null}
            </div>
        );
    }

    const _renderRows = () => {
        const { columns, actions, rows, rowOnClick } = props;
        const { data } = rows;
        const renderRowItem = (item: any, index: number) => {
            return (
                <div key={`row-item-${index}`} className={[
                    S.Layout.container,
                    S.Layout.flexRow,
                    S.Layout.crossAxisCenter,
                    S.Padding._2,
                    S.Padding.Left_6,
                    S.Border.Radius_20].join(" ")}>
                    <div className={[S.Layout.flex_2, S.Layout.textCenter, S.Fonts.Montserrat, S.Padding.Top_5, S.Padding.Bottom_5, S.Border.Radius_5, S.Fonts.Size_11, S.Fonts.Colour_bluePrimary].join(" ")}>
                        {item}
                    </div>
                </div>
            );
        }
        const elements = data.map((row: any, index: number) => {
            const rowColour = (rowOnClick ? S.Background.whiteToYellow_Hover : S.Background.whiteTertiary);
            const rowClasses = [S.Layout.flexRow, S.Margin.Bottom_1, S.Border.Radius_5, rowColour].join(" ");
            const onClick = () => {
              if (rowOnClick) { rowOnClick(row) };
            };

            const _renderActions = (action: any, index: number) => {
                if (!actions || !action.onClick || !action.label) {
                    throw new Error("Actions passed to a table require both an onClick function and label");
                }
                return (
                    <div className={[S.Layout.flexRow, S.Layout.flex_1, S.Margin.Bottom_1].join(" ")}>
                    <React.Fragment key={`action-keys-${index}`}>
                        <Button submit={() => {action.onClick(row)}} type={Enums.Buttons.Alternate} >{action.label}</Button>
                        {(actions.length === index) ? null : <UI.Spacer size={Enums.Sizes.XSmall} />}
                    </React.Fragment>
                    </div>
                );
            }

            if (rows.dataType === Enums.TableTypes.Object) {
                return (
                    <div key={`row-${index}`} onClick={onClick} className={rowClasses}>
                        {columns.map((key, index) => {
                            const value: any = row[key];
                            // if (typeof value == 'boolean') {
                            //     return renderRowItem(value.toString(), index);
                            // }
                            if (typeof value == 'function') {
                                return renderRowItem(<Button submit={value} type={Enums.Buttons.Alternate} >Click Me</Button>, index);
                            }
                            return renderRowItem(value, index);
                        })}
                        {actions ? renderRowItem(actions.map(_renderActions), (columns.length + 1)) : null}
                    </div>
                )
            }

            if (rows.dataType === Enums.TableTypes.Array) {
                return (
                    <div key={`row-${index}`} onClick={onClick} className={rowClasses}>
                        {row.map(renderRowItem)}
                    </div>
                );
            }
            return null;
        });

        

        return (
            <div className={[S.Layout.flexCol, S.Border.Radius_5].join(" ")}>
                {elements.length > 0 ? elements : 
                    <div className={[S.Layout.flexRow, S.Margin.Bottom_1, S.Border.Radius_5, S.Background.whiteTertiary].join(" ")}>
                        <div className={[
                            S.Layout.container,
                            S.Layout.textCenter,
                            
                            S.Border.Radius_20].join(" ")}>
                            <div className={[S.Fonts.Montserrat, S.Padding.Top_5, S.Padding.Bottom_5, S.Border.Radius_5, S.Fonts.Size_12, S.Fonts.Colour_bluePrimary].join(" ")}>
                                No Results Found
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

    const _renderPagination = () => {
        if (!props.pagination?.data) {
            return null;
        }
        return (
            <div className={[S.Width._100, S.Margin.Top_20, S.Margin.Bottom_20, S.Layout.flexRow, S.Layout.mainAxisEnd, S.Layout.crossAxisCenter].join(" ")}>
                <Button submit={() => {props.pagination?.update("previous")}} type={props.pagination.data.hasPreviousPage ? Enums.Buttons.Alternate : Enums.Buttons.Disabled}>Previous</Button>
                <UI.Spacer size={Enums.Sizes.XSmall} />
                <div className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_16, S.Fonts.Colour_bluePrimary, S.Padding._6, S.Padding.Left_10, S.Padding.Right_10, S.Background.whiteTertiary, S.Border.Radius_4].join(" ")}>
                    {props.pagination.data.pageNumber} of {props.pagination.data.totalPages}
                </div>
                <UI.Spacer size={Enums.Sizes.XSmall} />
                <Button submit={() => {props.pagination?.update("next")}} type={props.pagination.data.hasNextPage ? Enums.Buttons.Alternate : Enums.Buttons.Disabled}>Next</Button>
            </div>
        );
    }

    return (
        <div className={[S.Width._100, S.Border.Radius_5].join(" ")}>
            {_renderColumns()}
            {_renderRows()}
            {_renderPagination()}
        </div>
    );
}

Table.defaultProps = {
    columns: ['Name', 'Role', 'Area', 'Shifts', 'Status'],
    rows: {
      dataType: Enums.TableTypes.Array,
      data: [
          ['Person of Interest', 'User Role', 'Fitzroy', 10, 'Active'],
          ['Person of Interest', 'User Role', 'Fitzroy', 10, 'Active'],
          ['Person of Interest', 'User Role', 'Fitzroy', 10, 'Active'],
          ['Person of Interest', 'User Role', 'Fitzroy', 10, 'Active']
      ]
    },
    rowOnClick: null
}

export default Table;
// --------------------------------
