// *******************************************************
// PlayerGames - view/_private/_PlayerGames.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const PlayerGameSearchFilter  = [
  {type: Enums.Filters.Date, key: 'player_entered_date_from', label: "Race Day Start Range"},
  {type: Enums.Filters.Date, key: 'player_entered_date_to', label: "Race Day End Range"},
];

const PlayerGames = () => {

  const navigate = REACT_ROUTER_DOM.useNavigate();
  const dispatch = STATE.useAppDispatch();
  const { playerID } = REACT_ROUTER_DOM.useParams();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.PlayerGamesSearch));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.PlayerGamesSearch));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);
  const pagination = STATE.useAppSelector(state => state.PAGINATION[Enums.API_ENPOINTS.PlayerGamesSearch]);

  const executeSearch = React.useCallback(async (filters: any) => {
    try {
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.PlayerGamesSearch,
          pagination: {
            direction: 'next'
          },
          data: {
            "player_id": playerID,
            ...filters,
          }
        })
      ).unwrap();
    } catch (e: any) {} 
  }, [dispatch, playerID]);

  React.useEffect(() => {
    try {
      executeSearch({});
    } catch (e: any) {} 
  }, [executeSearch]);

  const executePagination = async (paginationDirection: "next" | "previous") => {
    try {
      await dispatch(
        await STATE.SLICES.APP.paginationAction({
          apiURL: Enums.API_ENPOINTS.PlayerGamesSearch,
          pagination: {
            direction: paginationDirection
          }
        })
      ).unwrap();
    } catch (e: any) {} // Errors are handled elsewhere
  }

  const gameslist = STATE.useAppSelector(state => state.GAMES.player);
  const gamesTableList = ["Player Entered", "Entered Date", "Club ID", "Race Day ID", "Track Name", "Status"];
  const actions = [
    {
      label: "View Leaderboard",
      onClick: (row: any) => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.GameLeaderboard, {clubID: row["Club ID"], racedayID: row["Race Day ID"]})) }
    },
    {
      label: "View Ledger",
      onClick: (row: any) => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.PlayerGamedayLedger, {playerID: playerID, racedayID: row["Race Day ID"]})) }
    }
  ]

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Games</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Text.Paragraph>Showing Player Games for {playerID}</UI.Text.Paragraph>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Filter filters={PlayerGameSearchFilter} submit={executeSearch} />
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Text.Error didError={didError} errorMessage={requestErrorMessage} />
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {isRequestInProgress ? <UI.SkeletonTable columns={gamesTableList} /> : null }
          {!isRequestInProgress && gameslist?.length > 0 ? <UI.Table columns={gamesTableList} rows={{data: gameslist, dataType: Enums.TableTypes.Object}} actions={actions} pagination={{data: pagination, update: executePagination}}  /> : null }
          {!isRequestInProgress && gameslist?.length === 0 ?  <UI.Table columns={gamesTableList} rows={{data: []}} /> : null}

        </div>
      </AppWrapper>
  )
}

export default PlayerGames;
// --------------------------------
