// *******************************************************
// Hero Component - _components/_ui/_HeroBanner.js
// -------------------------------------------------------
// This is the hero banner for all public pages of the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as ASSETS from '../../_assets';
import * as S from '../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  height?: string,
  children?: React.ReactNode
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const NavigationBar = (props: Props) => {  

  const logoImageStyles = {
    backgroundImage: `url(${ASSETS.IMAGES.logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  }

  return (
    <div className={[S.Layout.Width_100, S.Layout.flexRow, S.Layout.bothAxisCenter, S.Background.defaultBG].join(" ")} style={{width: "100vw", height: props.height || 80}}>
      <div></div>
      <div style={{...logoImageStyles, width: 60, height: 60}}></div>
      <div></div>
    </div>
  );
}

export default NavigationBar;
// --------------------------------