// *******************************************************
// PlayerSearch - view/_private/_PlayerSearch.js
// -------------------------------------------------------
// This is the search for players page of the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const playerSearchFilter  = [
  {type: Enums.Filters.Text, key: 'full_name', label: "Full Name"},
  {type: Enums.Filters.Text, key: 'email_address', label: "Email Address"},
  {type: Enums.Filters.Date, key: 'date_of_birth', label: "Date of Birth"},
  {type: Enums.Filters.Text, key: 'display_name', label: "Stable Name"},
];

const playerSearchFilterRegulatory  = [
    {type: Enums.Filters.Text, key: 'full_name', label: "Full Name"},
];

const PlayerSearch = () => {
    const { usertypes} = STATE.useAppSelector(state => state.AUTH);
  const navigate = REACT_ROUTER_DOM.useNavigate();
  // Create Redux Dispatch Action
  const dispatch = STATE.useAppDispatch();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.PlayerSearch));
  const pagination = STATE.useAppSelector(state => state.PAGINATION[Enums.API_ENPOINTS.PlayerSearch]);

  const executeSearch = async (filters: AnyAsyncThunk) => {
    try {
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.PlayerSearch,
          pagination: {
            direction: 'next'
          },
          data: {
            ...filters
          }
        })
      ).unwrap();
    } catch (e: any) {} // Errors are handled elsewhere
  }

  const executePagination = async (paginationDirection: "next" | "previous") => {
    try {
      await dispatch(
        await STATE.SLICES.APP.paginationAction({
          apiURL: Enums.API_ENPOINTS.PlayerSearch,
          pagination: {
            direction: paginationDirection
          }
        })
      ).unwrap();
    } catch (e: any) {} // Errors are handled elsewhere
  }

  const playersList = STATE.useAppSelector(state => state.PLAYERS.list);
  const playerTableColumns = ["playerID", "fullname", "username", "dob", "stableName", "greenID", "playerStatus"];
  const rowOnClick = (row: any) => { 
    navigate(Enums.ROUTE_BUILDER(Enums.Routes.PlayerDetail, {playerID: row.playerID}))
  }

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Search</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
            {usertypes.includes(Enums.UserTypes.REGULATORY_REPORTING) ? <UI.Filter filters={playerSearchFilterRegulatory} submit={executeSearch} /> : <UI.Filter filters={playerSearchFilter} submit={executeSearch} />}
          <UI.Spacer size={Enums.Sizes.Medium} />
          {isRequestInProgress ? <UI.SkeletonTable columns={playerTableColumns} /> : null }
          {!isRequestInProgress && playersList?.length > 0 ? <UI.Table columns={playerTableColumns} rowOnClick={rowOnClick} rows={{data: playersList, dataType: Enums.TableTypes.Object}} pagination={{data: pagination, update: executePagination}} /> : null}
          {!isRequestInProgress && playersList?.length === 0 ?  <UI.Table columns={playerTableColumns} rows={{data: []}} /> : null}
        </div>
      </AppWrapper>
  )
}

export default PlayerSearch;
// --------------------------------
