// *******************************************************
// Helper Controller File - _controllers/_helper.ts
// -------------------------------------------------------
// Contains helper functions for making things perhaps cleaner
// then in other places
// --------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
// --------------------------------

// *******************************************
// Type Declarations
// -------------------------------------------
type Session = {
  token: string | null | undefined,
  tokenIssue: number | null | undefined,
  tokenExpiry: number | null | undefined,
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const validateToken = (session: Session) => {
  return (session.token && session.tokenIssue && session.tokenExpiry && (session.tokenExpiry + session.tokenIssue) >= Date.now());
}

export const saveRefreshToken = (session: Session) => {
  sessionStorage.setItem("refreshToken", session.token as string);
  sessionStorage.setItem("refreshIssue", session.tokenIssue?.toString() as string);
  sessionStorage.setItem("refreshExpiry", session.tokenExpiry?.toString() as string);
}

export const clearRefreshToken = () => {
  sessionStorage.setItem("refreshToken", "");
  sessionStorage.setItem("refreshIssue", "");
  sessionStorage.setItem("refreshExpiry", "");
}

export const getRefreshToken = () => {
  const refreshToken = sessionStorage.getItem("refreshToken");
  const refreshIssue = sessionStorage.getItem("refreshIssue");
  const refreshExpiry = sessionStorage.getItem("refreshExpiry");

  const session: Session = {
    token: refreshToken || null,
    tokenIssue: (refreshIssue) ? parseInt(refreshIssue) : null,
    tokenExpiry: (refreshExpiry) ? parseInt(refreshExpiry) : null,
  }

  return session;
}
const AUTH = {
  validateToken,
  saveRefreshToken,
  getRefreshToken
}
export default AUTH;
// --------------------------------
