// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as AUTH from './_auth';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type TransactionsState = {
  list: any[]
}

export const TRANSACTIONS = REDUX_TOOLKIT.createSlice({
  name: 'TRANSACTIONS',
  initialState: {
    list: []
  } as TransactionsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(AUTH.logout, (state, action) => {
        state.list = [];
    })
    .addCase(APP.fetchAction.rejected, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.FinancialTransactions) { 
        state.list = [];
      }
    })
    .addCase(APP.fetchAction.pending, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.FinancialTransactions) { 
        state.list = [];
      }
    })
    .addMatcher(REDUX_TOOLKIT.isAnyOf(APP.fetchAction.fulfilled, APP.paginationAction.fulfilled), (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.FinancialTransactions) {
        if (!action.payload?.financial_transactions) {
          return;
        }
        state.list = action.payload.financial_transactions.map((transaction: any) => {
          return {
            "Sequence Number": transaction.sequence_number,
            "Licensee": transaction.licensee,
            "Client Name": transaction.client_name,
            "Client Account": transaction.client_account,
            "Client IP Address": transaction.client_ip_address,
            "Client Location": transaction.client_location,
            "Transaction Type": transaction.transaction_type,
            "Transaction Amount": transaction.transaction_amount,
            "Transaction Comment": transaction.transaction_comment,
            "Date/Time": transaction.datetime,
            "Time Zone": transaction.timezone,
            "Account Balance": transaction.account_balance,
            "Transaction Status": transaction.transaction_status,
            "Transaction Status Date/Time": transaction.transaction_status_datetime
          }
        });
      }
    })
  }
})
export default TRANSACTIONS;
// --------------------------------