// *******************************************************
// Slices Index - state/reducer/index.ts
// -------------------------------------------------------
// The is the index file to that imports and combines all our
// state reducers into one reducer for use in the Redux Store.
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX from 'redux';
import * as _APP from './_app';
import * as _AUTH from './_auth';
import * as _BETS from './_bets';
import * as _DATA from './_data';
import * as _GAMES from './_games';
import * as _LEADERBOARD from './_leaderboard';
import * as _LEDGER from './_ledger';
import * as _PAGINATION from './_pagination';
import * as _PLAYERS from './_players';
import * as _TRANSACTIONS from './_transactions';
import * as _SERVERHEALTH from './_serverhealth';
// --------------------------------

// *******************************************
// Reducer Imports
// -------------------------------------------
import {default as APP_SLICE} from './_app';
import {default as AUTH_SLICE} from './_auth';
import {default as BETS_SLICE} from './_bets';
import {default as DATA_SLICE} from './_data';
import {default as GAMES_SLICE} from './_games';
import {default as LEADERBOARD_SLICE} from './_leaderboard';
import {default as LEDGER_SLICE} from './_ledger';
import {default as PLAYERS_SLICE} from './_players';
import {default as PAGINATION_SLICE} from './_pagination';
import {default as TRANSACTIONS_SLICE} from './_transactions';
import {default as SERVERHEALTH_SLICE} from './_serverhealth';
// --------------------------------

// *******************************************
// Module Exports
// -------------------------------------------
export const APP = _APP;
export const AUTH = _AUTH;
export const BETS = _BETS;
export const DATA = _DATA;
export const GAMES = _GAMES;
export const LEADERBOARD = _LEADERBOARD;
export const LEDGER = _LEDGER;
export const PLAYERS = _PLAYERS;
export const PAGINATION = _PAGINATION;
export const TRANSACTIONS = _TRANSACTIONS;
export const SERVERHEALTH = _SERVERHEALTH;
// --------------------------------

// *******************************************
// Reducer Export
// -------------------------------------------
export default REDUX.combineReducers({
  APP: APP_SLICE.reducer,
  AUTH: AUTH_SLICE.reducer,
  BETS: BETS_SLICE.reducer,
  DATA: DATA_SLICE.reducer,
  GAMES: GAMES_SLICE.reducer,
  LEADERBOARD: LEADERBOARD_SLICE.reducer,
  LEDGER: LEDGER_SLICE.reducer,
  PLAYERS: PLAYERS_SLICE.reducer,
  PAGINATION: PAGINATION_SLICE.reducer,
  TRANSACTIONS: TRANSACTIONS_SLICE.reducer,
  SERVERHEALTH: SERVERHEALTH_SLICE.reducer
});
// --------------------------------
