// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as AUTH from './_auth';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type BetsState = {
  list: any[]
}

export const BETS = REDUX_TOOLKIT.createSlice({
  name: 'BETS',
  initialState: {
    list: []
  } as BetsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(AUTH.logout, (state, action) => {
        state.list = [];
    })
    .addCase(APP.fetchAction.pending, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.BettingTransactions) { 
        state.list = [];
      }
    })
    .addMatcher(REDUX_TOOLKIT.isAnyOf(APP.fetchAction.fulfilled, APP.paginationAction.fulfilled), (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.BettingTransactions) {
        if (!action.payload?.betting_transactions) {
          return;
        }
        state.list = action.payload.betting_transactions.map((bet: any) => {
          return {
            "Sequence Number": bet.sequence_number,
            "Licensee": bet.licensee,
            "Client Name": bet.client_name,
            "Client Account": bet.client_account,
            "Client IP Address": bet.client_ip_address,
            "Client Location": bet.client_location,
            "Betting Channel": bet.betting_channel,
            "Betting Channel Name": bet.betting_channel_name,
            "Ticket Number": bet.ticket_number,
            "Bet Description": bet.bet_description,
            "Bet Amount": bet.bet_amount,
            "Odds": bet.odds,
            "Bet Status": bet.bet_status,
            "Bet Status Date/Time": bet.bet_status_datetime,
            "Time Zone": bet.timezone
          }
        });
      }
    })
  }
})
export default BETS;
// --------------------------------