// *******************************************************
// RacedayCreate - view/_private/_RacedayCreate.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as yup from 'yup';
import * as Luxon from 'luxon';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import * as CONTROLLERS from '../../_controllers';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const dateKey = 'date';
const trackKey = 'track';

const RacedayCreate = () => {

  const navigate = REACT_ROUTER_DOM.useNavigate();
  const dispatch = STATE.useAppDispatch();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.RacedayCreate));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.RacedayCreate));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);
  const trackList = STATE.useAppSelector(state => state.DATA.tracks.list);
  const trackOptions = STATE.useAppSelector(state => state.DATA.tracks.options);

  const handleCreateGame = async (form: any) => {
    try {
      const track = trackList.find((t) => t.id === parseInt(form[trackKey]));
      const date = Luxon.DateTime.fromISO(form[dateKey]).toUTC().toISO();
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.RacedayCreate,
          data: {
            planned_country_code: track.country_code,
            planned_state_code: track.state_code,
            planned_track_name: track.name,
            raceday_date: date,
          }
        })
      ).unwrap();
      // Successfully created the track, navigate to success page
      navigate(Enums.Routes.RacedayCreateSuccess);
    } catch (e: any) {
      console.log(e);
    } // Errors are handled elsewhere
  }

  const schema = {
    elements: [
      CONTROLLERS.HELPER.createFormElement(dateKey, Enums.Inputs.Date, "Raceday Date"),
      CONTROLLERS.HELPER.createFormElement(trackKey, Enums.Inputs.Dropdown, "Track", {options: trackOptions || []}),
    ],
    validator: yup.object().shape({
      [dateKey]: yup.string().required(),
      [trackKey]: yup.string().required(),
    }),
    buttonLabel: 'Create Raceday'
  }

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Raceday Create</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Control.Form schema={schema} onSubmit={handleCreateGame} requestInProgress={isRequestInProgress} />
          <UI.Text.Error didError={didError} errorMessage={requestErrorMessage} />
        </div>
      </AppWrapper>
  )
}

export default RacedayCreate;
// --------------------------------
