// *******************************************************
// PlayerBets - view/_private/_PlayerBets.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------


const PlayerBets = () => {

  const dispatch = STATE.useAppDispatch();
  const { playerID } = REACT_ROUTER_DOM.useParams();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.BettingTransactions));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.BettingTransactions));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);
  const pagination = STATE.useAppSelector(state => state.PAGINATION[Enums.API_ENPOINTS.BettingTransactions]);
  const [filterState, setFilterState] = React.useState({});

  const baseFilter = [
    {type: Enums.Filters.Date, key: 'datetime_from', label: "Bets Date From*"},
    {type: Enums.Filters.Date, key: 'datetime_to', label: "Bets Date To*"},
    {type: Enums.Filters.Text, key: 'ticket_number', label: "Ticket Number"},
    {type: Enums.Filters.Text, key: 'client_name', label: "Full Name"},
  ];

  let PlayerBetsFilter;
  if (!playerID) {
    PlayerBetsFilter = [
      {type: Enums.Filters.Text, key: 'client_account', label: "Player ID"},
    ].concat(baseFilter);
  } else {
    PlayerBetsFilter = baseFilter;
  }

  const executeSearch = async (filters: any) => {

    try {
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.BettingTransactions,
          pagination: {
            direction: 'next'
          },
          data: {
            "client_account": playerID,
            ...filters,
          }
        })
      ).unwrap();
      setFilterState(filters);
    } catch (e: any) {} // Errors are handled elsewhere
  }

  const generateCSVFile = async () => {
    const response = await dispatch(

      await STATE.SLICES.APP.fetchAction({

        apiURL: Enums.API_ENPOINTS.BettingTransactionsDownload,

        data: {
          "client_account": playerID,
          ...filterState
        }

      })

    ).unwrap();
    downloadCSV(response)

  }

  function downloadCSV(csvStr:any) {

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'export-betting-transactions.csv';
    hiddenElement.click();
}

  const executePagination = async (paginationDirection: "next" | "previous") => {
    try {
      await dispatch(
        await STATE.SLICES.APP.paginationAction({
          apiURL: Enums.API_ENPOINTS.BettingTransactions,
          pagination: {
            direction: paginationDirection
          }
        })
      ).unwrap();
    } catch (e: any) {} // Errors are handled elsewhere
  }

  const betslist = STATE.useAppSelector(state => state.BETS.list);
  const betsTableList = ["Sequence Number", "Licensee", "Client Name", "Client Account", "Client Location", "Client IP Address", "Betting Channel", "Betting Channel Name","Ticket Number", "Bet Description", "Bet Amount", "Odds", "Bet Status", "Bet Status Date/Time", "Time Zone"];

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Bets</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {playerID ? <UI.Text.Paragraph>Showing Bets for {playerID}</UI.Text.Paragraph> : null}
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Filter filters={PlayerBetsFilter} submit={executeSearch} />
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Text.Error didError={didError} errorMessage={`${requestErrorMessage}`} />
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {filterState ? <UI.Button submit={() => generateCSVFile()  } type={Enums.Buttons.Alternate}> Generate CSV export for all available player betting transaction fields</UI.Button> : null}
          <UI.Spacer size={Enums.Sizes.XSmall} horizontal />
          {isRequestInProgress ? <UI.SkeletonTable columns={betsTableList} /> : null }
          {!isRequestInProgress && betslist?.length > 0 ? <UI.Table columns={betsTableList} rows={{data: betslist, dataType: Enums.TableTypes.Object}} pagination={{data: pagination, update: executePagination}}  /> : null }
          {!isRequestInProgress && betslist?.length === 0 ?  <UI.Table columns={betsTableList} rows={{data: []}} /> : null}
        </div>
      </AppWrapper>
  )
}

export default PlayerBets;
// --------------------------------
