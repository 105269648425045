// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum NavType {
  Link,
  Function,
}
export default NavType;
// --------------------------------
