// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum ROUTES {
  Login = '/',
  Unauthorized = '/unauthorized',
  Dashboard = '/dashboard',
  PlayerSearch = '/player',
  PlayerDetail = '/player/:playerID',
  PlayerGames = '/player/games/:playerID',
  PlayerManage = '/player/manage/:playerID',
  PlayerTransactions = '/player/transactions',
  PlayerTransactionsPlayerID = '/player/transactions/:playerID',
  PlayerBets = '/player/bets',
  PlayerBetsPlayerID = '/player/bets/:playerID',
  PlayerGamedayLedger = '/player/:playerID/games/ledger/:racedayID',
  GameSearch = '/game',
  GameManage = '/game/:clubID/:racedayID/manage',
  GameLeaderboard = '/game/:clubID/:racedayID/leaderboard',
  RacedayCreate = '/raceday/create',
  RacedayCreateSuccess = '/raceday/create/success',
  ServerHealth = '/serverhealth',
  
}

export const ROUTE_BUILDER = (route: ROUTES, params: any) => {
  switch (route) {
    case ROUTES.PlayerDetail:
      if (!params.playerID) { throw new Error("Player ID is required for route: " + ROUTES.PlayerDetail)}
      return `/player/${params.playerID}`;
    case ROUTES.PlayerGames:
      if (!params.playerID) { throw new Error("Player ID is required for route: " + ROUTES.PlayerGames)}
      return `/player/games/${params.playerID}`;
    case ROUTES.PlayerManage:
      if (!params.playerID) { throw new Error("Player ID is required for route: " + ROUTES.PlayerManage)}
      return `/player/manage/${params.playerID}`;
    case ROUTES.PlayerTransactionsPlayerID:
      if (!params.playerID) { throw new Error("Player ID is required for route: " + ROUTES.PlayerTransactionsPlayerID)}
      return `/player/transactions/${params.playerID}`;
    case ROUTES.PlayerBetsPlayerID:
      if (!params.playerID) { throw new Error("Player ID is required for route: " + ROUTES.PlayerBetsPlayerID)}
      return `/player/bets/${params.playerID}`;
    case ROUTES.PlayerGamedayLedger:
      if (!params.playerID || !params.racedayID) { throw new Error("Player ID & Race Day ID are required for route: " + ROUTES.PlayerGamedayLedger)}
      return `/player/${params.playerID}/games/ledger/${params.racedayID}`;
    case ROUTES.GameManage:
      if (!params.clubID || !params.racedayID) { throw new Error("Club ID & Race Day ID are required for route: " + ROUTES.GameManage)}
      return `/game/${params.clubID}/${params.racedayID}/manage`;
    case ROUTES.GameLeaderboard:
      if (!params.clubID || !params.racedayID) { throw new Error("Club ID & Race Day ID are required for route: " + ROUTES.GameLeaderboard)}
      return `/game/${params.clubID}/${params.racedayID}/leaderboard`;
    default: 
      return route;
  }
}

export default ROUTES;
// --------------------------------
