// *******************************************************
// Helper Controller File - _controllers/_helper.ts
// -------------------------------------------------------
// Contains helper functions for making things perhaps cleaner
// then in other places
// --------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as Enums from '../_enums';
// --------------------------------

// *******************************************
// Initalisation
// -------------------------------------------

// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const createFormElement = (key: string, type: Enums.Inputs, label: string, dynamicProps?: any) => {
  return {key, type, label, dynamicProps};
}

const HELPER = {
  createFormElement
}
export default HELPER;
// --------------------------------
