// *******************************************************
// PlayerDetail - view/_private/_PlayerDetail.js
// -------------------------------------------------------
// This is the search for players page of the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import CircleIcon from "@mui/icons-material/Circle";
import {green, orange} from "@mui/material/colors";
import * as Luxon from "luxon";
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------

const ServerHealth = () => {

  const dispatch = STATE.useAppDispatch();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.ServerHealth));
  const serverHealth = STATE.useAppSelector(state => state.SERVERHEALTH.serverHealth);

    const executeSearch = async () => {
        try {
            await dispatch(
                await STATE.SLICES.APP.fetchAction({
                    apiURL: Enums.API_ENPOINTS.ServerHealth,
                     method: 'get'
                })
            ).unwrap();
        } catch (e: any) {
            console.log(e);
        } // Errors are handled elsewhere
    }

    const renderHealth = (service: boolean) => {

    if (service){
        return <CircleIcon sx={{color: green[500]}}/>
    } else return <CircleIcon sx={{color: orange[500]}}/>

    }


    const _renderSubmitButton = (submitFunction: () => Promise<void>) => {
        if (isRequestInProgress) {
            return (
                <div className={[S.Background.bluePrimary_Hover, S.Fonts.Colour_whiteTertiary, S.Border.Width_1, S.Border.Colour_bluePrimary, S.Border.Radius_4, S.Layout.flexCol, S.Layout.bothAxisCenter, S.Padding._4].join(" ")}>
                    <UI.Spinner />
                </div>
            );
        } else {
            return (
                <UI.Button submit={submitFunction} type={Enums.Buttons.Alternate}>Check Health</UI.Button>
            )
        }
    }

    const _renderHealthTable = () => {
        if (!isRequestInProgress && serverHealth) {
            return (
                <div className={[S.Layout.flexRow].join(" ")}>
                    <div>
                        <UI.Text.ListItem>API Health Status:</UI.Text.ListItem>
                        <UI.Text.ListItem>API Release Version:</UI.Text.ListItem>
                        <UI.Text.ListItem>Server:</UI.Text.ListItem>
                        <UI.Text.ListItem>Betting Database: </UI.Text.ListItem>
                        <UI.Text.ListItem>Application Database:</UI.Text.ListItem>
                        <UI.Text.ListItem>Dynamic Odds:  </UI.Text.ListItem>
                        <UI.Text.ListItem>Dynamic Odds Subscriptions Enabled:  </UI.Text.ListItem>
                        <UI.Text.ListItem>Graph Server:</UI.Text.ListItem>
                        <UI.Text.ListItem>Fiserv Payment URL Online: </UI.Text.ListItem>
                        <UI.Text.ListItem>Fiserv API online: </UI.Text.ListItem>
                        <UI.Text.ListItem>Green ID:</UI.Text.ListItem>
                        <UI.Text.ListItem>Locate:</UI.Text.ListItem>
                    </div>
                    <div>
                        <UI.Text.ListItem>{serverHealth.api_health_status} </UI.Text.ListItem>
                        <UI.Text.ListItem>{serverHealth.api_release_version} </UI.Text.ListItem>
                        <UI.Text.ListItem>{serverHealth.server.external_ip_address} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.databases.betting_database.online)} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.databases.application_database.online)} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.datafeed.dynamic_odds.online)} </UI.Text.ListItem>
                        <UI.Text.ListItem>{serverHealth.datafeed.dynamic_odds.subscriptions_enabled.toString()} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.graph_server.online)} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.services.fiserv.payment_url_online)} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.services.fiserv.api_online)} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.services.fiserv.payment_url_online)} </UI.Text.ListItem>
                        <UI.Text.ListItem>{renderHealth(serverHealth.services.loqate.online)} </UI.Text.ListItem>
                    </div>
                </div>
            );
        }
        else if (!serverHealth)
            return (
            <>
                <UI.Text.Paragraph>Server Offline... :( </UI.Text.Paragraph>
            </>
        );
    }

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
            <UI.Text.Heading>Group 1 Sports Server Health</UI.Text.Heading>
            <UI.Spacer size={Enums.Sizes.Medium}/>
            <div className={[S.Layout.flexCol, S.Width._15].join(" ")}>
                {_renderSubmitButton(() => executeSearch())}
            </div>
            <UI.Text.ParagraphSmall>Last Updated: </UI.Text.ParagraphSmall>
            {serverHealth.last_updated ? <UI.Text.ParagraphSmall> {Luxon.DateTime.fromISO(serverHealth.last_updated).toFormat("HH:mm:ss dd/MM/yyyy")} </UI.Text.ParagraphSmall> : null}
            {_renderHealthTable()}
        </div>
      </AppWrapper>
  )
}

export default ServerHealth;
// --------------------------------
