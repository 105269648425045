// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum Inputs {
  Text,
  Password,
  Date,
  Dropdown
}
export default Inputs;
// --------------------------------
