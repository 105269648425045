// *******************************************************
// PlayerTransactions - view/_private/_PlayerTransactions.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const PlayerTransactions = () => {

  const dispatch = STATE.useAppDispatch();
  const { playerID } = REACT_ROUTER_DOM.useParams();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.FinancialTransactions));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.FinancialTransactions));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);
  const pagination = STATE.useAppSelector(state => state.PAGINATION[Enums.API_ENPOINTS.FinancialTransactions]);
  const [filterState, setFilterState] = React.useState({});

  const baseFilter = [
    {type: Enums.Filters.Date, key: 'datetime_from', label: "Transactions Date From*"},
    {type: Enums.Filters.Date, key: 'datetime_to', label: "Transactions Date To*"},
    {type: Enums.Filters.Text, key: 'client_name', label: "Full Name"},
  ];

  let PlayerTransactionsFilter;
  if (!playerID) {
    PlayerTransactionsFilter = [
      {type: Enums.Filters.Text, key: 'client_account', label: "Player ID"},
    ].concat(baseFilter);
  } else {
    PlayerTransactionsFilter = baseFilter;
  }

  const executeSearch = async (filters: any) => {
    try {
      const getFinancialTransactions = async () => {
        await dispatch(
          await STATE.SLICES.APP.fetchAction({
            apiURL: Enums.API_ENPOINTS.FinancialTransactions,
            pagination: {
              direction: 'next'
            },
            data: {
              "client_account": playerID,
              ...filters,
            }
          })
        ).unwrap();
      }
      getFinancialTransactions();
      setFilterState(filters);
    } catch (e: any) {}  // Errors are handled elsewhere
  }



  const generateCSVFile = async () => {

    const response = await dispatch(
      await STATE.SLICES.APP.fetchAction({

        apiURL: Enums.API_ENPOINTS.FinancialTransactionsDownload,

        data: {
          "client_account": playerID,
          ...filterState
        }

      })

    ).unwrap();
    downloadCSV(response)

  }

  function downloadCSV(csvStr:any) {

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'export-financial-transactions.csv';
    hiddenElement.click();
}

  const executePagination = async (paginationDirection: "next" | "previous") => {
    try {
      await dispatch(
        await STATE.SLICES.APP.paginationAction({
          apiURL: Enums.API_ENPOINTS.FinancialTransactions,
          pagination: {
            direction: paginationDirection
          }
        })
      ).unwrap();
    } catch (e: any) {} // Errors are handled elsewhere
  }
  const transactionlist = STATE.useAppSelector(state => state.TRANSACTIONS.list);
  const transactionsTableList = ["Sequence Number", "Licensee", "Client Name", "Client Account", "Client Location", "Client IP Address", "Transaction Type", "Transaction Amount", "Transaction Comment", "Date/Time", "Time Zone", "Account Balance", "Transaction Status", "Transaction Status Date/Time"];

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Transactions</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {playerID ? <UI.Text.Paragraph>Showing Transactions for {playerID}</UI.Text.Paragraph> : null}
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Filter filters={PlayerTransactionsFilter} submit={executeSearch}/>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Text.Error didError={didError} errorMessage={`${requestErrorMessage} - Please select a From and To date`} />
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {filterState ? <UI.Button submit={() => { generateCSVFile()  }} type={Enums.Buttons.Alternate}> Generate CSV export for all available player financial transaction fields</UI.Button> : null}
          <UI.Spacer size={Enums.Sizes.XSmall} horizontal />
          {isRequestInProgress ? <UI.SkeletonTable columns={transactionsTableList} /> : null }
          {!isRequestInProgress && transactionlist?.length > 0 ? <UI.Table  columns={transactionsTableList} rows={{data: transactionlist, dataType: Enums.TableTypes.Object}} pagination={{data: pagination, update: executePagination}} /> : null }
          {!isRequestInProgress && transactionlist?.length === 0 ?  <UI.Table columns={transactionsTableList} rows={{data: []}} /> : null}
        </div>
      </AppWrapper>
  )
}

export default PlayerTransactions;
// --------------------------------
