// *******************************************************
// Filter Date Component - _components/_ui/_Filter/_Date.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../../_styles';
import { Control } from '../index';
import type { FilterType } from './index';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  filter: FilterType,
  register?: any,
  setValue: any,
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const FilterDate = (props: Props) => {  
  return (
    <div className={[S.Layout.Width_100, S.Layout.flexRow].join(" ")}>
      <Control.Date 
        id={props.filter.key} 
        label={props.filter.label}
        setValue={props.setValue}
        register={(props.register) ? props.register(props.filter.key) : undefined}
         />
    </div>
  );
}

export default FilterDate;
// --------------------------------