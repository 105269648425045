// *******************************************************
// Heading Component - _components/_ui/_Text/_Heading.ts
// -------------------------------------------------------
// This is the heading text for all screens
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  children?: React.ReactNode,
  fontColour?: string
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Heading = (props: Props) => { 

  return (
    <div className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_45, props.fontColour || S.Fonts.Colour_whiteTertiary].join(" ")}>
        {props.children}
    </div>
  );
}

export default Heading;
// --------------------------------