// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type PaginationState = {
  [key: string]: Pagination
}

export type Pagination = {
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
  hasNextPage: boolean,
  hasPreviousPage: boolean
  totalPages: number,
  totalCount: number,
  lastRequestBody: any | null
}

export const paginationDefault = {
  pageNumber: 0,
  pageSize: 20,
  sortColumn: "player_id",
  sortDirection: "ASC",
  hasNextPage: false,
  hasPreviousPage: false,
  totalPages: 0,
  totalCount: 0,
  lastRequestBody: null
}

export const PAGINATION = REDUX_TOOLKIT.createSlice({
  name: 'PAGINATION',
  initialState: {} as PaginationState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(APP.fetchAction.pending, (state, action) => {
        if (action.meta.arg.pagination) {
          state[action.meta.arg.apiURL] = paginationDefault;
        }
      })
      .addCase(APP.fetchAction.fulfilled, (state, action) => {
        if (action.meta.arg.pagination && action.payload.paging_info) {
          state[action.meta.arg.apiURL].pageNumber = action.payload.paging_info.page_number;
          state[action.meta.arg.apiURL].pageSize = action.payload.paging_info.page_size;
          state[action.meta.arg.apiURL].sortColumn = action.payload.paging_info.sort_column;
          state[action.meta.arg.apiURL].sortDirection = action.payload.paging_info.sort_direction;
          state[action.meta.arg.apiURL].hasNextPage = action.payload.paging_info.has_next_page;
          state[action.meta.arg.apiURL].hasPreviousPage = action.payload.paging_info.has_previous_page;
          state[action.meta.arg.apiURL].totalPages = action.payload.paging_info.total_pages;
          state[action.meta.arg.apiURL].totalCount = action.payload.paging_info.total_count;
          state[action.meta.arg.apiURL].lastRequestBody = action.meta.arg.data;
        }
      })
      .addCase(APP.paginationAction.fulfilled, (state, action) => {
        if (action.meta.arg.pagination && action.payload.paging_info) {
          state[action.meta.arg.apiURL].pageNumber = action.payload.paging_info.page_number;
          state[action.meta.arg.apiURL].pageSize = action.payload.paging_info.page_size;
          state[action.meta.arg.apiURL].sortColumn = action.payload.paging_info.sort_column;
          state[action.meta.arg.apiURL].sortDirection = action.payload.paging_info.sort_direction;
          state[action.meta.arg.apiURL].hasNextPage = action.payload.paging_info.has_next_page;
          state[action.meta.arg.apiURL].hasPreviousPage = action.payload.paging_info.has_previous_page;
          state[action.meta.arg.apiURL].totalPages = action.payload.paging_info.total_pages;
          state[action.meta.arg.apiURL].totalCount = action.payload.paging_info.total_count;
        }
      })
      .addMatcher(REDUX_TOOLKIT.isAnyOf(APP.fetchAction.rejected, APP.paginationAction.rejected), (state, action) => {
        if (action.meta.arg.pagination) {
          state[action.meta.arg.apiURL] = paginationDefault;
        }
      })
      

       
  }
})

export default PAGINATION;
// --------------------------------