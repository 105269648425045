// *******************************************************
// GameManage - view/_private/_GameManage.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as yup from 'yup';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import * as CONTROLLERS from '../../_controllers';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const gameEntryFeeInCents = 'game_entry_fee_in_cents';
const prizePayoutConfigurationID = 'prize_payout_configuration_id';

const GameManage = () => {

  const navigate = REACT_ROUTER_DOM.useNavigate();
  const dispatch = STATE.useAppDispatch();
  const [ success, setSuccess ] = React.useState(false);
  const { clubID, racedayID } = REACT_ROUTER_DOM.useParams();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.GameUpdate));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.GameUpdate));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);
  const prizePayoutConfigOptions = STATE.useAppSelector(state => state.DATA.prizePayoutConfigs.options);
  const _clubID = parseInt(clubID as string);
  const _racedayID = parseInt(racedayID as string);
  const game = STATE.useAppSelector(state => state.GAMES.clubs.find((g) => g["Club ID"] === _clubID && g["Race Day ID"] === _racedayID))
  
  const handleUpdatePlayer = async (form: any) => {
    try {
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.GameUpdate,
          data: {
            club_id: _clubID,
            raceday_id: _racedayID,
            game_entry_fee_in_cents: form[gameEntryFeeInCents],
            prize_payout_configuration_id: parseInt(form[prizePayoutConfigurationID]),
          }
        })
      ).unwrap();
      // Successfully Updated the player
      setSuccess(true);
    } catch (e: any) {
      console.log(e);
    } // Errors are handled elsewhere
  }

  const schema = {
    elements: [
      CONTROLLERS.HELPER.createFormElement(gameEntryFeeInCents, Enums.Inputs.Text, "Game Entry Fee in Cents"),
      CONTROLLERS.HELPER.createFormElement(prizePayoutConfigurationID, Enums.Inputs.Dropdown, "Prize Payout Configuration", {options: prizePayoutConfigOptions || []}),
    ],
    validator: yup.object().shape({
      [gameEntryFeeInCents]: yup.number(),
      [prizePayoutConfigurationID]: yup.string(),
    }),
    buttonLabel: 'Update Game'
  }

  const gamePrizePayoutOption = prizePayoutConfigOptions.find((o) => o.value === game["Prize Payout Raw"]);
  const defaultFormValues = {
    [gameEntryFeeInCents]: game["Game Entry Fee In Cents"],
    [prizePayoutConfigurationID]: gamePrizePayoutOption.key,
  }

  const _renderForm = () => {
    if (!success) {
      return (
        <>
          <UI.Control.Form schema={schema} defaultValues={defaultFormValues} onSubmit={handleUpdatePlayer} requestInProgress={isRequestInProgress} />
          <UI.Text.Error didError={didError} errorMessage={requestErrorMessage} />
        </>
      );
    }
    return null;
  }
  const _renderSuccess = () => {

    if (success) {
      return (
        <>
          <UI.Text.Paragraph>Game was updated successfully</UI.Text.Paragraph>
          <UI.Spacer size={Enums.Sizes.Small} />
          <UI.Button type={Enums.Buttons.Main} submit={() => {navigate(-1)}}>Go Back</UI.Button>
        </>
      );
    }
    return null;
  }

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Game Manage</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {_renderForm()}
          {_renderSuccess()}
        </div>
      </AppWrapper>
  )
}

export default GameManage;
// --------------------------------
