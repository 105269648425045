// *******************************************************
// GameSearch - view/_private/_GameSearch.js
// -------------------------------------------------------
// This is the search for players page of the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const GameSearchFilter  = [
  {type: Enums.Filters.List, key: 'mandatory_club', label: "Mandatory Club", options: Enums.DROPDOWNS.Boolean},
  {type: Enums.Filters.List, key: 'public_club', label: "Public Club", options: Enums.DROPDOWNS.Boolean},
  {type: Enums.Filters.Number, key: 'club_id', label: "Club ID"},
  {type: Enums.Filters.Number, key: 'raceday_id', label: "Race Day ID"},
  {type: Enums.Filters.Date, key: 'raceday_date', label: "Race Day date"},
  {type: Enums.Filters.SortCol, key: 'sort_column', label: "Sort Column"},
  {type: Enums.Filters.Date, key: 'raceday_date', label: "Race Day date"},
  {type: Enums.Filters.SortDirection, key: 'sort_direction', label: "Sort Direction"},
];

const GameSearch = () => {
  const navigate = REACT_ROUTER_DOM.useNavigate();
  // Create Redux Dispatch Action
  const dispatch = STATE.useAppDispatch();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.GameSearch));
  const pagination = STATE.useAppSelector(state => state.PAGINATION[Enums.API_ENPOINTS.GameSearch]);

  const executeSearch = async (filters: any) => {
    try {
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.GameSearch,
          pagination: {
            direction: 'next'
          },
          data: {
            ...filters
          }
        })
      ).unwrap();
    } catch (e: any) {} // Errors are handled elsewhere
  }

  const executePagination = async (paginationDirection: "next" | "previous") => {
    try {
      await dispatch(
        await STATE.SLICES.APP.paginationAction({
          apiURL: Enums.API_ENPOINTS.GameSearch,
          pagination: {
            direction: paginationDirection
          }
        })
      ).unwrap();
    } catch (e: any) {} // Errors are handled elsewhere
  }

  const gamesList = STATE.useAppSelector(state => state.GAMES.clubs);
  const gamesTableColumns = ["Club ID", "Club Name", "Race Day ID", "Raceday Date", "Track", "Point Scoring Version", "Entry Fee", "Prize Template", "Status"];
  
  const actions = [
    {
      label: "Leaderboard",
      onClick: (row: any) => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.GameLeaderboard, {clubID: row["Club ID"], racedayID: row["Race Day ID"]})) }
    },
    {
      label: "Manage",
      onClick: (row: any) => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.GameManage, {clubID: row["Club ID"], racedayID: row["Race Day ID"]})) }
    }
  ]

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Game Search</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Filter filters={GameSearchFilter} defaultActiveKeys={['mandatory_club', 'public_club']} defaultValues={{mandatory_club: true, public_club: true, sort_column: "raceday_date", sort_direction: "DESC"}} submit={executeSearch} />
          <UI.Spacer size={Enums.Sizes.Medium} />
          {isRequestInProgress ? <UI.SkeletonTable columns={gamesTableColumns} /> : null }
          {!isRequestInProgress && gamesList?.length > 0 ? <UI.Table columns={gamesTableColumns} actions={actions} rows={{data: gamesList, dataType: Enums.TableTypes.Object}} pagination={{data: pagination, update: executePagination}}  /> : null}
          {!isRequestInProgress && gamesList?.length === 0 ?  <UI.Table columns={gamesTableColumns} rows={{data: []}} /> : null}
        </div>
      </AppWrapper>
  )
}

export default GameSearch;
// --------------------------------
