// *******************************************************
// State Index - state/index.js
// -------------------------------------------------------
// This file houses all the required files for the
// application state to sit underneath. All files and calls
// making changes to state will be only be called by files
// outside this structure as actions.
// --------------------------------

// *******************************************
// Imports
// -------------------------------------------
import * as REACT_REDUX from 'react-redux';
import * as SLICES_ from './_slices';
import * as INIT_ from './_init';
import type { TypedUseSelectorHook } from 'react-redux'
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => INIT_.AppDispatch = REACT_REDUX.useDispatch;
export const useAppSelector: TypedUseSelectorHook<INIT_.RootState> = REACT_REDUX.useSelector;
export const SLICES = SLICES_;
export const INIT = INIT_;
// --------------------------------
