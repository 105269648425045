// *******************************************************
// Heading Component - _components/_ui/_Text/_Heading.ts
// -------------------------------------------------------
// This is the heading text for all screens
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  didError: boolean,
  errorMessage: string | undefined | null,
  fontColour?: string
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Error = (props: Props) => { 
  if (!props.didError || !props.errorMessage) {
    return null;
  }
  return (
    <div className={[S.Fonts.Montserrat_Bold, S.Padding.Top_10, S.Padding.Bottom_10, S.Fonts.Size_16, props.fontColour || S.Fonts.Colour_redTertiary].join(" ")}>
        {props.errorMessage}
    </div>
  );
}

export default Error;
// --------------------------------