// *******************************************************
// ProtectedRoute - view/_private/__ProtectedRoute.js
// -------------------------------------------------------
// This is the proteted route component, routes wrapped in 
// this will require user authentication to continue
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import * as CONTROLLERS from '../../_controllers';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  children?: React.ReactNode,
  routeAccess: Enums.UserTypes[]
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const ProtectedRoute = (props: Props) => {

  let navigate = REACT_ROUTER_DOM.useNavigate();
  const dispatch = STATE.useAppDispatch();

  const isInitialised = STATE.useAppSelector(state => state.APP.intialised);
  const { accessToken, usertypes } = STATE.useAppSelector(state => state.AUTH);

  if (isInitialised && !accessToken) {
    const checkAuthentication = async () => {
      const refreshSession = CONTROLLERS.AUTH.getRefreshToken();
      try {
        await dispatch(
          await STATE.SLICES.APP.fetchAction({
            apiURL: Enums.API_ENPOINTS.RefreshToken,
            data: {
              refresh_token: refreshSession.token,
            }
          })
        ).unwrap();
      } catch (e: any) {
        await dispatch(STATE.SLICES.AUTH.logout());
        navigate(Enums.Routes.Login);
      }
    }
    checkAuthentication(); 
  }

  if (isInitialised && !props.routeAccess.some(v => usertypes.includes(v))) {
    navigate(Enums.Routes.Unauthorized, { replace: true });
  }

  return (
    <>
      {props.children ? props.children : <REACT_ROUTER_DOM.Outlet />}
    </>
  )


}

export default ProtectedRoute;
// --------------------------------
