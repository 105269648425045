// *******************************************************
// Components Index - components/index.js
// -------------------------------------------------------
// This is the index file for all components across the application.
// A component file should be dumb in that it will only render
// itself based on properties that are passed into it
// -------------------------------------------

// *******************************************
// Imports
// -------------------------------------------
// import * as BEHAVIOR_ from './_behavior';
import * as LAYOUT_ from './_layout';
import * as UI_ from './_ui';
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
// export const BEHAVIOR = BEHAVIOR_;
export const LAYOUT = LAYOUT_;
export const UI = UI_;
// --------------------------------

