// *******************************************************
// Spacer Component - _components/_ui/_Spacer.js
// -------------------------------------------------------
// A generic Spacer component
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as Enums from '../../_enums';
import * as S from '../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  size: Enums.Sizes,
  horizontal?: boolean
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Spacer = (props: Props) => {  

  let spacerMargin;
  switch (props.size) {
    case (Enums.Sizes.XSmall):
      spacerMargin = S.Margin._5;
      break;
    case (Enums.Sizes.Small):
      spacerMargin = S.Margin._10;
      break;
    case (Enums.Sizes.Medium):
      spacerMargin = S.Margin._20;
      break;
    case (Enums.Sizes.Large):
      spacerMargin = S.Margin._50;
      break;
    case (Enums.Sizes.XLarge):
      spacerMargin = S.Margin._100;
      break;
  }

  if (props.horizontal) {
    return <div className={[S.Layout.flexCol, spacerMargin].join(" ")} style={{height: 1, width: 1}}></div>
  }

  return (
    <div className={[S.Layout.Width_100, S.Layout.flexCol, spacerMargin].join(" ")}></div>
  );
}

export default Spacer;
// --------------------------------