// *******************************************************
// Spacer Component - _components/_ui/_Spacer.js
// -------------------------------------------------------
// A generic Spacer component
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as Enums from '../../_enums';
import * as S from '../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  type: Enums.Buttons,
  children?: React.ReactElement | String,
  disabled?: boolean,
  submit?: <T extends Object> (data: T) => void,
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const STYLES_BY_TYPE = {
  [Enums.Buttons.Main]: [S.Background.redPrimary_Hover, S.Fonts.Colour_whiteTertiary, S.Border.Width_1, S.Border.Colour_redPrimary, S.Border.Radius_4],
  [Enums.Buttons.Alternate]: [S.Background.bluePrimary_Hover, S.Fonts.Colour_whiteTertiary, S.Border.Width_1, S.Border.Colour_bluePrimary, S.Border.Radius_4],
  [Enums.Buttons.Outline]: [S.Background.transparentToBlue_Hover, S.Border.Width_1, S.Border.Colour_bluePrimary, S.Fonts.Colour_blueToWhite_Hover, S.Border.Radius_4],
  [Enums.Buttons.Disabled]: [S.Background.greyPrimary, S.Border.Width_1, S.Border.Colour_greyPrimary, S.Fonts.Colour_greySecondary, S.Border.Radius_4],
}

export const Button = (props: Props) => {  

  if (props.type === undefined) {
    throw new Error("Button Type is required.");
  }

  const _submit = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (props.submit) {
      props.submit({});
    }
  }

  const baseStyles = STYLES_BY_TYPE[props.type];

  return (
    <div className={[...baseStyles, S.Layout.Width_100, S.Layout.flexCol, S.Layout.bothAxisCenter, S.Padding._10, S.Padding.Top_5, S.Padding.Bottom_5].join(" ")}
        onClick={(props.type === Enums.Buttons.Disabled) ? () => {} : _submit}>
        {props.children}
    </div>
  );
}

Button.defaultProps = {
  disabled: false
}

export default Button;
// --------------------------------