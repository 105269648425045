// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as AUTH from './_auth';
import * as Enums from '../../_enums';
import * as Luxon from 'luxon';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type GameState = {
  player: any[],
  clubs: any[],
  prizePayoutConfigs: any[],
}
function statusConvert(status: number){
  switch (status) {
    case 0:
        return "Planned";
    case 1:
        return "Scheduled";
    case 2:
        return "Active";
    case 3:
        return "Final";
    default:
      throw new Error("Status: " + status);
  }
}

export const GAMES = REDUX_TOOLKIT.createSlice({
  name: 'GAMES',
  initialState: {
    player: [],
    clubs: [],
    prizePayoutConfigs: []
  } as GameState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(AUTH.logout, (state, action) => {
        state.player = [];
        state.clubs = [];
        state.prizePayoutConfigs = [];
    })
    .addCase(APP.fetchAction.pending, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerGamesSearch) { 
        state.player = [];
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.GameSearch) { 
        state.clubs = [];
      }
    })
    .addMatcher(REDUX_TOOLKIT.isAnyOf(APP.fetchAction.fulfilled, APP.paginationAction.fulfilled), (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerGamesSearch) {
        if (!action.payload?.club_player_game_summaries) {
          return;
        }
        state.player = action.payload.club_player_game_summaries.map((game: any) => {
          return {
            "Player Entered": game.player_entered ? "true" : "false",
            "Entered Date": (game.player_entered_date !== null) ? Luxon.DateTime.fromISO(game.player_entered_date).toFormat("yyyy LLL dd - ccc HH:mm:ss") : "null",
            "Club ID": game.club_id,
            "Race Day ID": game.raceday_id,
            "Track Name": game.raceday_track_name,
            "Status": game.raceday_status,
          }
        });
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.GameSearch) {
        if (!action.payload?.club_games) {
          return;
        }
        state.clubs = action.payload.club_games.map((game: any) => {
          //const gamePointScoringVersion = state.prizePayoutConfigs.find((p) => (p.id === game.prize_payout_configuration_id));
          return {
            "Club ID": game.club_id,
            "Club Name": game.club_name,
            "Race Day ID": game.raceday_id,
            "Raceday Date": Luxon.DateTime.fromISO(game.raceday_date).toFormat("yyyy LLL dd - ccc HH:mm:ss"),
            "Track": game.raceday_track_name,
            "Point Scoring Version": game.point_scoring_version,
            "Prize Payout Raw": game.prize_payout_configuration_id,
            "Entry Fee":  `${(game.game_entry_fee_in_cents / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}`,
            "Game Entry Fee In Cents": game.game_entry_fee_in_cents,
            "Prize Template": game.prize_payout_name,
            "Status": statusConvert(game.raceday_status)
          }
        });
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PrizePayoutConfigurations) {
        if (action.payload) {
          state.prizePayoutConfigs = action.payload;
        }
      }
    })
  }
})
export default GAMES;
// --------------------------------