// *******************************************************
// Table Desktop - _components/_ui/_Table/_TableDesktop.js
// -------------------------------------------------------
// This is the desktop Table
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
import * as ReactSpring from 'react-spring';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  columns: string[],
  rows: any,
  rowOnClick?: (row: any) => {}
}
// --------------------------------

// *******************************************
// Component Implementation
// -------------------------------------------
// const interp = i: any => r: any => `translate3d(0, ${15 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

export const SkeletonTable = (props: Props) => {

    const _renderColumns = () => {
        const { columns } = props;
        const elements = columns.map((item, index) => {
            return (
                <div key={`col-${index}`} className={[
                    S.Layout.container,
                    S.Padding._5,
                    S.Padding.Left_20].join(" ")}>
                    <div className={[S.Fonts.Montserrat_Bold, S.Padding.Top_10, S.Padding.Bottom_10, S.Fonts.Size_16, S.Fonts.Colour_whiteTertiary].join(" ")}>
                        {item}
                    </div>
                </div>
            );
        });
        return (
            <div className={[S.Layout.flexRow, S.Background.blueTertiary, S.Border.Radius_5].join(" ")}>
                {elements}
            </div>
        );
    }

    const springs = ReactSpring.useSprings(props.rows.data.length, props.rows.data.map((t:any, i:number) => ({
        loop: {reverse: true},
        to: { opacity: 0.9 },
        from: { opacity: 0.3 },
        config: { duration: 1000 },
        delay: 200 * i ,
        reset: true
    })));

    const ReactSpringAnimatedDiv = ReactSpring.animated.div;

    return (
        <div className={[S.Width._100, S.Border.Radius_5].join(" ")}>
            {_renderColumns()}
            <div className={[S.Layout.flexCol, S.Width._100, S.Border.Radius_5].join(" ")}>
                {springs.map((spring: any, index: number) => {
                    return (
                        <ReactSpringAnimatedDiv key={`row-index-${index}`} className={[S.Layout.flexRow, S.Margin.Bottom_1, S.Border.Radius_5, S.Background.whiteTertiary].join(" ")} style={spring}>
                            <div className={[
                                S.Width._100,
                                S.Layout.container,
                                S.Layout.bothAxisCenter,
                                S.Padding._10,
                                S.Border.Radius_20].join(" ")}>
                                <div className={[S.Fonts.Montserrat, S.Layout.textCenter, S.Layout.bothAxisCenter, S.Padding.Top_15, S.Padding.Bottom_15, S.Border.Radius_5, S.Fonts.Size_12, S.Fonts.Colour_bluePrimary].join(" ")}>
                                    {/* <UI.Spinner width={20} height={20} /> */}
                                </div>
                            </div>
                        </ReactSpringAnimatedDiv>
                    );
                })}
            </div>
        </div>
    );
}

SkeletonTable.defaultProps = {
    columns: ['Name', 'Role', 'Area', 'Shifts', 'Status'],
    rows: {
      dataType: Enums.TableTypes.Array,
      data: [
          [''], [''], [''], [''], ['']
      ]
    },
    rowOnClick: null
}

export default SkeletonTable;
// --------------------------------
