// *******************************************************
// Dropdown Component - _components/_ui/_Dropdown.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as ReactSpring from 'react-spring';
import * as ReactHookForm from 'react-hook-form';
import * as S from '../../../../_styles';
import InputStyles from '../_Input/_input.module.scss';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  id?: string
  label?: string
  children?: React.ReactNode
  options?: Array<Option>
  onSelect?: (selectedOption: Option) => void
  register?: ReactHookForm.UseFormRegisterReturn<string> //comes directly from React Hook Form
  errorMessage?: any;
  defaultValue?: any
}

export type Option = {
  key: string,
  value: any,
  label: string
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Dropdown = (props: Props) => {
  
  const defaultOption = props.defaultValue ? props.options?.find((option: Option) => option.value === props.defaultValue) : null;
  const [option, setOption] = React.useState<Option | null>(defaultOption as Option | null);
  const [open, setOpen] = React.useState(false);
  const optionContainerStyles = ReactSpring.useSpring({ height: open ? 125 : 0, borderWidth: open ? 1 : 0 });

  const _renderOption = (currentOption: Option, index: number) => {
    return (
      <div 
        key={`dropdown_opt_${index}`} 
        onClick={() => {
          if (props.onSelect) {
            props.onSelect(currentOption);
          }
          setOption(currentOption); 
        }} 
        className={[S.Background.whiteToBlueTertiary_Hover, S.Padding._5, S.Fonts.Montserrat_Bold, S.Fonts.Size_14, S.Fonts.Colour_blueToWhite_Child_Hover, ].join(" ")}>
          <div className={[S.Fonts.child_Hover_Target].join(" ")}>{currentOption.label}</div>
      </div>
    );
    
  }
  
  const ReactSpringAnimatedDiv = ReactSpring.animated.div;

  return (
    <div className={[S.Layout.relative, InputStyles.inputContainer, S.Fonts.pointer].join(" ")} onClick={() => {
        setOpen(!open)
      }}>
      <input 
        className={[S.Fonts.pointer, option?.label ? InputStyles.hasText : ""].join(" ")} 
        style={{height: 60}}
        id={props.id}
        value={option?.label || ""}
        disabled
        required />
      <label
          htmlFor={props.id} 
          className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_14, S.Fonts.Colour_whitePrimary].join(" ")}>{props.label}</label>
      <ReactSpringAnimatedDiv className={[S.Layout.absoluteTopLeft, S.Width._100, S.Background.whiteTertiary, S.Border.Width_1, S.Border.Colour_bluePrimary, S.Scroll.Y].join(" ")} style={{...optionContainerStyles, top: 60, zIndex: 9999}}>
          {props.options?.map(_renderOption)}
      </ReactSpringAnimatedDiv>
      
    </div>
  );
}

export default Dropdown;
// --------------------------------