// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum Usertypes {
  SUPER_ADMINISTRATOR = "SUPER_ADMINISTRATOR",
  ADMIN = "ADMIN",
  REPORTING = "REPORTING",
  REGULATORY_REPORTING = "REGULATORY_REPORTING",
  PLAYER = "PLAYER",
}

export const CONVERT_USERTYPE_TO_ENUM = (usertype: string) => {
  switch (usertype) {
    case "system_administrator":
        return Usertypes.SUPER_ADMINISTRATOR;
    case "admin":
        return Usertypes.ADMIN;
    case "reporting":
        return Usertypes.REPORTING;
    case "regulatory_reporting":
        return Usertypes.REGULATORY_REPORTING;
    case "player":
        return Usertypes.PLAYER;
    default:
      throw new Error("Usertype not found: " + usertype);
  }
}

export default Usertypes;
// --------------------------------
