// *******************************************************
// Filter List Component - _components/_ui/_Filter/_List.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../../_styles';
import { Control} from '../index';
import { FilterType } from './index';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  filter: FilterType,
  setValue: any,
  defaultValue?: any
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const FilterList = (props: Props) => {  

  return (
    <div className={[S.Layout.Width_100, S.Layout.flexRow].join(" ")}>
      <Control.Dropdown 
        id={props.filter.key} 
        label={props.filter.label} 
        options={props.filter.options}
        defaultValue={props.defaultValue}
        onSelect={(option:any) => {props.setValue(props.filter.key, option.value)}} />
    </div>
  );
}

export default FilterList;
// --------------------------------