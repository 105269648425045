// *******************************************************
// RacedayCreate - view/_private/_RacedayCreate.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const RacedayCreate = () => {

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Raceday Create</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Text.Paragraph>Your raceday was created successfully.</UI.Text.Paragraph>
        </div>
      </AppWrapper>
  )
}

export default RacedayCreate;
// --------------------------------
