// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import JWT_DECODE from "jwt-decode";
import * as CONTROLLERS from '../../_controllers';
import * as APP from './_app';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type AuthState = {
  isAuthed: boolean,
  accessToken: string | null | undefined,
  accessTokenIssue: number | null | undefined,
  accessTokenExpiry: number | null | undefined
  usertypes: Enums.UserTypes[]
  playerID: string | null | undefined
}
// This reducer is NOT persisted to localstorage
// NEVER store a JWT access_token in Local Storage!!!!
export const AUTH = REDUX_TOOLKIT.createSlice({
  name: 'AUTH',
  initialState: {
    isAuthed: false,
    accessToken: null,
    accessTokenIssue: null,
    accessTokenExpiry: null,
    usertypes: [],
    playerID: null
  } as AuthState,
  reducers: {
    logout: (state) => {
      state.isAuthed = false;
      state.accessToken = null;
      state.accessTokenExpiry = null;
      state.accessTokenIssue = null;
      state.usertypes = [];
      state.playerID = null;
      CONTROLLERS.AUTH.clearRefreshToken();
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(APP.fetchAction.fulfilled, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.Login ||
          action.meta.arg.apiURL === Enums.API_ENPOINTS.RefreshToken) { 
        // Store the jwt token in memory
        const jwt = action.payload;
        const decodedToken: any = JWT_DECODE(jwt.access_token);
        state.isAuthed = true;
        state.accessToken = jwt.access_token;
        state.accessTokenExpiry = jwt.expires_in_seconds * 1000;
        state.accessTokenIssue = Date.now();
        state.usertypes = decodedToken.resource_access["group1sports-api"].roles.map((access: string) => Enums.USERTYPES.CONVERT_USERTYPE_TO_ENUM(access));
        state.playerID = jwt.player_id;
        // Store the refresh token in sessionStorage
        CONTROLLERS.AUTH.saveRefreshToken({
          token: jwt.refresh_token, 
          tokenExpiry: jwt.refresh_expires_in_seconds * 1000, 
          tokenIssue: Date.now()
        });
      }
    })
  }
})
export const { logout } = AUTH.actions;
export default AUTH;
// --------------------------------