// *******************************************************
// Login - view/_public/_Login.js
// -------------------------------------------------------
// This is the sign up / create account page for the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as yup from 'yup';
import * as S from '../../_styles';
import { LAYOUT, UI } from '../../_components';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import * as CONTROLLERS from '../../_controllers';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const emailKey = 'email';
const passwordKey = 'password';
const schema = {
  elements: [
    CONTROLLERS.HELPER.createFormElement(emailKey, Enums.Inputs.Text, "Email"),
    CONTROLLERS.HELPER.createFormElement(passwordKey, Enums.Inputs.Password, "Password"),
  ],
  validator: yup.object().shape({
    [emailKey]: yup.string().email().required(),
    [passwordKey]: yup.string().min(1).max(32).required(),
  }),
  buttonLabel: 'Login'
}

const Login = () => {
  // Create React Router navigation hook
  let navigate = REACT_ROUTER_DOM.useNavigate();
  // Create Redux Dispatch Action
  const dispatch = STATE.useAppDispatch();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.Login));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.Login));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);

  const handleLogin = async (form: any) => {
    try {
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.Login,
          data: {
            username: form[emailKey],
            password: form[passwordKey],
          }
        })
      ).unwrap();
      // Navigate to player search on successful login
      navigate(Enums.Routes.Dashboard);
    } catch (e: any) {} // Errors are handled elsewhere
  }

  return (
      <LAYOUT.SingleColumn>
        <UI.HeroBanner />
        <div className={[S.Width._100, S.Layout.textCenter, S.Padding._50].join(" ")} style={{maxWidth: '500px'}}>
          <UI.Text.Heading>Login</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Medium} />
          <UI.Control.Form schema={schema} onSubmit={handleLogin} requestInProgress={isRequestInProgress} />
          <UI.Text.Error didError={didError} errorMessage={requestErrorMessage} />
        </div>
      </LAYOUT.SingleColumn>
  )
}

export default Login;
// --------------------------------
