// *******************************************************
// Paragraph Component - _components/_ui/_Text/_Paragraph.ts
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  children?: React.ReactNode,
  fontColour?: string
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const ListItem = (props: Props) => {

  return (
    <div className={[S.Fonts.Montserrat, S.Padding._20 ,S.Fonts.Size_16, props.fontColour || S.Fonts.Colour_defaultBG].join(" ")}>
        {props.children}
    </div>
  );
}

export default ListItem;
// --------------------------------