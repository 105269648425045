// *******************************************************
// Single Column Component - _components/_structure/_SingleColumn.js
// -------------------------------------------------------
// This structural component lays out content in a single column, with 
// an optional hero banner at the top of the screen.
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  children?: React.ReactNode
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const SingleColumn = (props: Props) => {
  return (
    <div className={[S.Width._100, S.Height.Min_Fixed_100, S.Layout.flexCol, S.Layout.crossAxisCenter, S.Background.defaultBG].join(" ")}>
      {props.children}
    </div>
  );
}

export default SingleColumn;
// --------------------------------