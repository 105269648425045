// *******************************************************
// Styles Index File
// -------------------------------------------------------
// The is the index file to import all our base styles from
// -------------------------------------------

// *******************************************
// Imports
// -------------------------------------------
import Normalise_ from './_Normalise.module.scss';
import Background_ from './_Background.module.scss';
import Border_ from './_Border.module.scss';
import BoxShadow_ from './_BoxShadow.module.scss';
import Fill_ from './_Fill.module.scss';
import Fonts_ from './_Fonts.module.scss';
import Height_ from './_Height.module.scss';
import Layout_ from './_Layout.module.scss';
import Margin_ from './_Margin.module.scss';
import Padding_ from './_Padding.module.scss';
import Scroll_ from './_Scroll.module.scss';
import Stroke_ from './_Stroke.module.scss';
import Width_ from './_Width.module.scss';
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
export const Normalise = Normalise_;
export const Background = Background_;
export const Border = Border_;
export const BoxShadow = BoxShadow_;
export const Fill = Fill_;
export const Fonts = Fonts_;
export const Height = Height_;
export const Layout = Layout_;
export const Margin = Margin_;
export const Padding = Padding_;
export const Scroll = Scroll_;
export const Stroke = Stroke_;
export const Width = Width_;

const Styles =  {
    Normalise,
    Background,
    Border,
    BoxShadow,
    Fill,
    Fonts,
    Height,
    Layout,
    Margin,
    Padding,
    Scroll,
    Stroke,
    Width
};
export default Styles;
// --------------------------------
