// *******************************************************
// Components UI Index - components/_structure/index.js
// -------------------------------------------------------
// This is the index file for all structural UI components.
// -------------------------------------------

// *******************************************
// Imports
// -------------------------------------------
import SidebarNavigation_ from './_SidebarNavigation';
import SingleColumn_ from './_SingleColumn';
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
export const SidebarNavigation = SidebarNavigation_;
export const SingleColumn = SingleColumn_;
// --------------------------------