// *******************************************************
// AppWrapper - view/_private/__AppWrapper.js
// -------------------------------------------------------
// This is main UI elements that create the admin console
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import { LAYOUT, UI } from '../../_components';
import * as STATE from '../../_state';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  children?: React.ReactNode
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const AppWrapper = (props: Props) => {

  let navigate = REACT_ROUTER_DOM.useNavigate();
  const dispatch = STATE.useAppDispatch();
  const { usertypes, playerID } = STATE.useAppSelector(state => state.AUTH);
  
  const handleLogout = async () => {
    await dispatch(STATE.SLICES.AUTH.logout());
    navigate(Enums.Routes.Login, { replace: true });
  }

  const navigationHeight = '80px';
  const sidebarMinHeight = `calc(100vh - ${navigationHeight})`;

  let navigationLinks: any[] = [];
  if (usertypes.includes(Enums.UserTypes.SUPER_ADMINISTRATOR) || usertypes.includes(Enums.UserTypes.ADMIN)) {
    // ADMIN SIDEBAR
    navigationLinks = [
      {
        type: Enums.NavType.Link,
        label: "Dashboard",
        link: Enums.Routes.Dashboard
      },
      {
        type: Enums.NavType.Link,
        label: "Raceday Create",
        link: Enums.Routes.RacedayCreate
      },
      {
        type: Enums.NavType.Link,
        label: "Game Search",
        link: Enums.Routes.GameSearch
      },
      {
        type: Enums.NavType.Link,
        label: "Player Search",
        link: Enums.Routes.PlayerSearch
      },
      {
        type: Enums.NavType.Link,
        label: "Server Health",
        link: Enums.Routes.ServerHealth
      },
      {
        type: Enums.NavType.Function,
        label: "Logout",
        onClick: handleLogout
      }
    ];
  } else if (usertypes.includes(Enums.UserTypes.PLAYER)) {
    // PLAYER SIDEBAR
    navigationLinks = [
      {
        type: Enums.NavType.Link,
        label: "Dashboard",
        link: Enums.Routes.Dashboard
      },
      {
        type: Enums.NavType.Link,
        label: "View Games",
        link: Enums.ROUTE_BUILDER(Enums.Routes.PlayerGames, {playerID: playerID})
      },
      {
        type: Enums.NavType.Link,
        label: "View Bets",
        link: Enums.ROUTE_BUILDER(Enums.Routes.PlayerBetsPlayerID, {playerID: playerID})
      },
      {
        type: Enums.NavType.Link,
        label: "View Transactions",
        link: Enums.ROUTE_BUILDER(Enums.Routes.PlayerTransactionsPlayerID, {playerID: playerID})
      },
      {
        type: Enums.NavType.Function,
        label: "Logout",
        onClick: handleLogout
      }
    ];
  } else if (usertypes.includes(Enums.UserTypes.REPORTING) || usertypes.includes(Enums.UserTypes.REGULATORY_REPORTING)) {
    // REPORTING SIDEBAR
    navigationLinks = [
      {
        type: Enums.NavType.Link,
        label: "Dashboard",
        link: Enums.Routes.Dashboard
      },
      {
        type: Enums.NavType.Link,
        label: "Player Bets",
        link: Enums.Routes.PlayerBets
      },
      {
        type: Enums.NavType.Link,
        label: "Player Transactions",
        link: Enums.Routes.PlayerTransactions
      },
      {
        type: Enums.NavType.Link,
        label: "Player Search",
        link: Enums.Routes.PlayerSearch
      },
      {
        type: Enums.NavType.Function,
        label: "Logout",
        onClick: handleLogout
      }
    ];;
  }

  return (
      <LAYOUT.SingleColumn>
        <UI.NavigationBar height={navigationHeight} />
        <LAYOUT.SidebarNavigation minHeight={sidebarMinHeight} navigationLinks={navigationLinks}>
          {props.children}
        </LAYOUT.SidebarNavigation>
      </LAYOUT.SingleColumn>
  )
}

export default AppWrapper;
// --------------------------------
