// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as AUTH from './_auth';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type DataState = {
  playerStatuses: {
    list: any[],
    options: any[]
  },
  pointScoringVersions: {
    list: any[],
    options: any[]
  },
  prizePayoutConfigs: {
    list: any[],
    options: any[]
  },
  tracks: {
    list: any[],
    options: any[]
  },
}

const defaultState = {
  list: [],
  options: []
};

export const DATA = REDUX_TOOLKIT.createSlice({
  name: 'DATA',
  initialState: {
    playerStatuses: defaultState,
    pointScoringVersions: defaultState,
    prizePayoutConfigs: defaultState,
    tracks: defaultState
  } as DataState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(AUTH.logout, (state, action) => {
        state.playerStatuses = defaultState;
        state.pointScoringVersions = defaultState;
        state.prizePayoutConfigs = defaultState;
        state.tracks = defaultState;
    })
    .addCase(APP.fetchAction.fulfilled, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerStatuses) {
        if (action.payload) {
          const list = [].concat(action.payload);
          const options = action.payload.map((playerStatus: any) => {
            return { key: playerStatus.Value, value: playerStatus.Value, label: `${playerStatus.Name}` };
          });
          state.playerStatuses.list = list
          state.playerStatuses.options = options;
        }
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PointScoringVersions) {
        if (action.payload) {
          const list = [].concat(action.payload);
          const options = action.payload.map((pointScoringVersion: any) => {
            return { key: pointScoringVersion.id, value: pointScoringVersion.id, label: `${pointScoringVersion.comments}` };
          });
          state.pointScoringVersions.list = list
          state.pointScoringVersions.options = options;
        }
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PrizePayoutConfigurations) {
        if (action.payload) {
          const list = [].concat(action.payload);
          const options = action.payload.map((prizePayoutConfig: any) => {
            return { key: prizePayoutConfig.id, value: prizePayoutConfig.id, label: `${prizePayoutConfig.name}` };
          });
          state.prizePayoutConfigs.list = list
          state.prizePayoutConfigs.options = options;
        }
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.Tracks) {
        if (action.payload) {
          const list = [].concat(action.payload);
          const options = action.payload.map((track: any) => {
            return { key: track.id, value: track.id, label: `${track.name} - ${track.abbreviation}` };
          });
          state.tracks.list = list
          state.tracks.options = options;
        }
      }
    })
  }
})
export default DATA;
// --------------------------------