// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum API_ENDPOINTS {
  BettingTransactions = 'admin/betting-transactions/search',
  BettingTransactionsDownload = '/admin/betting-transactions/export/csv',
  BettingGamblingLedger = '/admin/gambling-ledger/export/csv',
  ClientGamblingLedger = '/admin/client-gambling-ledger/export/csv',
  GameSearch = '/admin/club-games/search',
  GameUpdate = '/admin/club-games/update',
  FinancialTransactions = '/admin/financial-transactions/search',
  FinancialTransactionsDownload = '/admin/financial-transactions/export/csv',
  GameLeaderboard = `/admin/leaderboard`,
  PlayerGamesSearch = '/admin/player-games/search',
  PlayerSearch = '/admin/players/search',
  PlayerPointsLedger = '/admin/players/:player_id/points-ledger',
  PlayerUpdate = '/admin/players/:player_id/update',
  PlayerActivityStatement = '/admin/players/generate-monthly-activity-statement',
  RacedayCreate = '/racedays/create',
  Login = '/users/login',
  RefreshToken = '/users/refresh-token',
  PlayerStatuses = '/application/data/player-statuses',
  PointScoringVersions = '/application/data/point-scoring-versions',
  PrizePayoutConfigurations = '/application/data/prize-payout-configurations',
  Tracks = '/application/data/tracks',
  ServerHealth = '/application/diagnostics',
}

export default API_ENDPOINTS;
// --------------------------------
