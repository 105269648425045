// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as AUTH from './_auth';
import * as Enums from '../../_enums';
import * as Luxon from 'luxon';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type LedgerState = {
  list: any[]
}

export const LEDGER = REDUX_TOOLKIT.createSlice({
  name: 'LEDGER',
  initialState: {
    list: []
  } as LedgerState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(AUTH.logout, (state, action) => {
        state.list = [];
    })
    .addCase(APP.fetchAction.pending, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerPointsLedger) { 
        state.list = [];
      }
    })
    .addCase(APP.fetchAction.fulfilled, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerPointsLedger) {
        if (!action.payload) {
          return;
        }
        
        state.list = action.payload.map((ledger: any) => {
          return {
            "Timestamp": Luxon.DateTime.fromISO(ledger.allocated_date).toFormat("yyyy LLL dd - ccc HH:mm:ss"),
            "Race Number": ledger.race_number,
            "Race Level": ledger.race_number,
            "Runner Name": ledger.runner_name,
            "Final Position": ledger.final_position,
            "Points Type": ledger.points_type,	
            "Points": ledger.points,
            "Streak Count": ledger.streak_count || 0,
            "Threat Count": ledger.threat_count || 0
          }
        });
      }
    })
  }
})
export default LEDGER;
// --------------------------------