// *******************************************************
// Filter Component - _components/_ui/_Filter.js
// -------------------------------------------------------
// A customisable filter component that can take props as the filter
// elements to build it self.
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as ReactHookForm from "react-hook-form";
import * as S from '../../../_styles';
import * as Enums from '../../../_enums';
import { Button, Spacer } from '../index';
import FilterNumber from './_Number';
import FilterText from './_Text';
import FilterDate from './_Date';
import FilterDateRange from './_DateRange';
import FilterList from './_List';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  filters?: FilterType[],
  submit?: Function,
  defaultActiveKeys?: any[],
  defaultValues?: any
}

export type FilterType = {
  type: Enums.Filters,
  key: string,
  label: string,
  options?: Array<any>,
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Filter = (props: Props) => {  
  
  const filterTypeText = {
    type: Enums.Filters.Text,
    key: 'name',
    label: "Name"
  };

  const filterTypeList = {
    type: Enums.Filters.List,
    key: 'venue',
    label: "Venue",
    options: [
      {key: "0", value: "MELB", label: "Melbourne"},
      {key: "1", value: "GEEL", label: "Geelong"},
      {key: "2", value: "BEND", label: "Bendigo"},
      {key: "3", value: "BALL", label: "Ballarat"},
    ]
  };

  const filterTypeDate = {
    type: Enums.Filters.Date,
    key: 'date',
    label: "Date",
  };

  const filterTypeDateRange = {
    type: Enums.Filters.DateRange,
    key: 'dateRange',
    label: "Date Range",
  };

  const filters = props.filters || [filterTypeText, filterTypeList, filterTypeDate, filterTypeDateRange];
  const [activeFilterKeys, setActiveFilterKeys] = React.useState<Array<string>>(props.defaultActiveKeys || []);
  const formRef = React.useRef(null);

  const { register, handleSubmit, setValue } = ReactHookForm.useForm({
    defaultValues: props.defaultValues || {},
  });

  const onSubmitFilter = () => {
    if (formRef?.current) {
      // @ts-ignore
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  }

  const _renderFilter = (filterKey: string, index: number) => {
    const filter = filters.find(f => f.key === filterKey);
    if (!filter) { throw new Error("Filter not found some how?"); }
    const spacer = index !== activeFilterKeys.length ? <Spacer size={Enums.Sizes.Small} /> : null
    switch (filter.type) {
      case Enums.Filters.List:
        return (
          <React.Fragment key={`filter-${filterKey}`}>
            <FilterList filter={filter} setValue={setValue} defaultValue={props.defaultValues[filterKey]} ></FilterList>
            {spacer}
          </React.Fragment>
        ); 
      case Enums.Filters.Date:
        return (
          <React.Fragment key={`filter-${filterKey}`}>
            <FilterDate filter={filter} setValue={setValue} register={register}></FilterDate>
            {spacer}
          </React.Fragment>
        ); 
      case Enums.Filters.DateRange:
        return (
          <React.Fragment key={`filter-${filterKey}`}>
            <FilterDateRange filter={filter}></FilterDateRange>
            {spacer}
          </React.Fragment>
        );
      case Enums.Filters.Number:
          return (
            <React.Fragment key={`filter-${filterKey}`}>
              <FilterNumber filter={filter} register={register}></FilterNumber>
              {spacer}
            </React.Fragment>
          );
      case Enums.Filters.Text:
      default: 
        return (
          <React.Fragment key={`filter-${filterKey}`}>
            <FilterText filter={filter} register={register}></FilterText>
            {spacer}
          </React.Fragment>
        );
    }
  }

  const _renderFilterSelectors = (filter: FilterType, index: number) => {
    const isActive = activeFilterKeys.includes(filter.key);
    const buttonType = isActive ? Enums.Buttons.Alternate : Enums.Buttons.Outline;
    const onClick = () => {
      if (isActive) {
        setValue(filter.key, null);
        setActiveFilterKeys(activeFilterKeys.filter((f) => f !== filter.key));
      } else {
        setActiveFilterKeys(activeFilterKeys.concat([filter.key]));
      }
    }
    return (
      <React.Fragment key={filter.key}>
        <Button type={buttonType} submit={onClick}>{filter.label}</Button>
        <Spacer size={Enums.Sizes.XSmall} horizontal />
      </React.Fragment>
    );
  }

  return (
    <div className={[S.Layout.Width_100, S.Layout.flexCol].join(" ")}>
  
      <div className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_24, S.Fonts.Colour_bluePrimary].join(" ")}>Select Filter</div>
      <Spacer size={Enums.Sizes.XSmall} />
      <div className={[S.Layout.flexRow].join(" ")}>
        {filters.map(_renderFilterSelectors)}
      </div>
      {activeFilterKeys.length > 0 ? <Spacer size={Enums.Sizes.Small} /> : null}
      <form ref={formRef} onSubmit={handleSubmit((form: any) => {
        if (props.submit) props.submit(form)
      })}>
        {activeFilterKeys.map(_renderFilter)}
      </form>
      <Spacer size={Enums.Sizes.Small} />
      <div className={[S.Layout.flexRow].join(" ")}>
      <Button type={Enums.Buttons.Alternate} submit={onSubmitFilter}>Search</Button> 
      </div>
    </div>
  );
}

export default Filter;
// --------------------------------