// *******************************************************
// Application Entry Point
// -------------------------------------------------------
// This is the entry point for the entire application.
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as REDUX from 'react-redux';
import * as REDUX_PERSIST from 'redux-persist/integration/react';
import * as STATE from './_state';
import * as CONTROLLERS from './_controllers';
import Root from './_Root';
import reportWebVitals from './reportWebVitals';
import './index.css';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root')!);
if (!root) {
  console.error("Root Element not found");
} else {
  // Configure our API handlers to include adding auth tokens and refresh tokens
  CONTROLLERS.API.configInterceptors(STATE.INIT.store);
  root.render(
    // <React.StrictMode>
      <REDUX.Provider store={STATE.INIT.store}>
        <REDUX_PERSIST.PersistGate loading={null} persistor={STATE.INIT.persistor}>
          <REACT_ROUTER_DOM.BrowserRouter>
            <Root />
          </REACT_ROUTER_DOM.BrowserRouter>
        </REDUX_PERSIST.PersistGate>
      </REDUX.Provider>
    // </React.StrictMode>
  );
}
// --------------------------------

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
