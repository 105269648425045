// *******************************************************
// Root - _Root.tsx
// -------------------------------------------------------
// This is the root strucutre file that shows all the
// different pages of the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as PAGES from './_pages';
import * as Enums from './_enums';
import * as STATE from './_state';
import * as CONTROLLERS from './_controllers';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Root = () => {

  let navigate = REACT_ROUTER_DOM.useNavigate();
  const dispatch = STATE.useAppDispatch();
  const isInitialised = STATE.useAppSelector(state => state.APP.intialised);
  const { isAuthed, usertypes } = STATE.useAppSelector(state => state.AUTH);
  
  
  // Only run once on page load!
  React.useEffect(() => {
    const checkInitalisation = async () => {
      if (!isInitialised) {
        const refreshSession = CONTROLLERS.AUTH.getRefreshToken();
        try {
          await dispatch(
            await STATE.SLICES.APP.fetchAction({
              apiURL: Enums.API_ENPOINTS.RefreshToken,
              data: {
                refresh_token: refreshSession.token,
              }
            })
          ).unwrap();
          await dispatch(STATE.SLICES.APP.initialise());
          // Get initial Application Data
        } catch (e: any) {
          await dispatch(STATE.SLICES.AUTH.logout());
          await dispatch(STATE.SLICES.APP.initialise());
          navigate(Enums.Routes.Login, { replace: true });
        }
      }
    }
    checkInitalisation();
  }, [isInitialised, dispatch, navigate]);

  // Run this whenever someone logs in!
  React.useEffect(() => {
    const checkAuth = async () => {
      if (isAuthed) {
        // Do anything we need to when we are first authenticated! (eg after a login)
        try {
          if (usertypes.includes(Enums.UserTypes.SUPER_ADMINISTRATOR) || usertypes.includes(Enums.UserTypes.ADMIN)) {
            // Get initial Application Data
            await Promise.all([
              dispatch(STATE.SLICES.APP.fetchAction({apiURL: Enums.API_ENPOINTS.PlayerStatuses, method: 'get'})),
              dispatch(STATE.SLICES.APP.fetchAction({apiURL: Enums.API_ENPOINTS.PointScoringVersions, method: 'get'})),
              dispatch(STATE.SLICES.APP.fetchAction({apiURL: Enums.API_ENPOINTS.PrizePayoutConfigurations, method: 'get'})),
              dispatch(STATE.SLICES.APP.fetchAction({apiURL: Enums.API_ENPOINTS.Tracks, method: 'get'})),
            ]);
          }
        } catch (e: any) {}
      }
    }
    checkAuth();
  }, [isAuthed, usertypes, dispatch]);

  if (!isInitialised) {
    return null;
  }

  return (
    <REACT_ROUTER_DOM.Routes>


      {/* Player User Access */}
      <REACT_ROUTER_DOM.Route element={<PAGES.PRIVATE.ProtectedRoute 
        routeAccess={[
          Enums.UserTypes.SUPER_ADMINISTRATOR,
          Enums.UserTypes.ADMIN,
          Enums.UserTypes.PLAYER,
        ]} />
      }>
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerGames} element={<PAGES.PRIVATE.PlayerGames />} />
        
      </REACT_ROUTER_DOM.Route>

      {/* Regulatory Reporting User Access */}
      <REACT_ROUTER_DOM.Route element={<PAGES.PRIVATE.ProtectedRoute 
        routeAccess={[
          Enums.UserTypes.SUPER_ADMINISTRATOR,
          Enums.UserTypes.ADMIN,
          Enums.UserTypes.REGULATORY_REPORTING,
          Enums.UserTypes.REPORTING,
        ]} />
      }>
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerBets} element={<PAGES.PRIVATE.PlayerBets />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerTransactions} element={<PAGES.PRIVATE.PlayerTransactions />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerSearch} element={<PAGES.PRIVATE.PlayerSearch />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerDetail} element={<PAGES.PRIVATE.PlayerDetail />} />
      </REACT_ROUTER_DOM.Route>

      {/* All User Access */}
      <REACT_ROUTER_DOM.Route element={<PAGES.PRIVATE.ProtectedRoute 
        routeAccess={[
          Enums.UserTypes.SUPER_ADMINISTRATOR,
          Enums.UserTypes.ADMIN,
          Enums.UserTypes.REPORTING,
          Enums.UserTypes.REGULATORY_REPORTING,
          Enums.UserTypes.PLAYER,
        ]} />
      }>
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerTransactionsPlayerID} element={<PAGES.PRIVATE.PlayerTransactions />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerBetsPlayerID} element={<PAGES.PRIVATE.PlayerBets />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.Dashboard} element={<PAGES.PRIVATE.Dashboard />} />
      </REACT_ROUTER_DOM.Route>
      
      

      {/* Public Pages */}
      <REACT_ROUTER_DOM.Route path={Enums.Routes.Login} element={<PAGES.PUBLIC.Login />} />
      <REACT_ROUTER_DOM.Route path={Enums.Routes.Unauthorized} element={<PAGES.PUBLIC.Unauthorized />} />
      <REACT_ROUTER_DOM.Route path={"*"} element={<PAGES.PUBLIC.NotFound />} />

      <REACT_ROUTER_DOM.Route element={<PAGES.PRIVATE.ProtectedRoute 
        routeAccess={[
          Enums.UserTypes.SUPER_ADMINISTRATOR
        ]} />
      }>

      {/* Private Pages */}
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerSearch} element={<PAGES.PRIVATE.PlayerSearch />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerManage} element={<PAGES.PRIVATE.PlayerManage />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.PlayerGamedayLedger} element={<PAGES.PRIVATE.PlayerGamedayLedger />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.GameSearch} element={<PAGES.PRIVATE.GameSearch />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.GameLeaderboard} element={<PAGES.PRIVATE.GameLeaderboard />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.GameManage} element={<PAGES.PRIVATE.GameManage />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.RacedayCreate} element={<PAGES.PRIVATE.RacedayCreate />} />
        <REACT_ROUTER_DOM.Route path={Enums.Routes.RacedayCreateSuccess} element={<PAGES.PRIVATE.RacedayCreateSuccess />} />
          <REACT_ROUTER_DOM.Route path={Enums.Routes.ServerHealth} element={<PAGES.PRIVATE.ServerHealth />} />
      </REACT_ROUTER_DOM.Route>

    </REACT_ROUTER_DOM.Routes>
  )
}

export default Root;
// --------------------------------
