// *******************************************************
// Filter Text Component - _components/_ui/_Filter/_Text.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../../_styles';
import { Control } from '../index';
import type { FilterType } from './index';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  filter: FilterType,
  register?: any
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const FilterNumber = (props: Props) => {  

  return (
    <div className={[S.Layout.Width_100, S.Layout.flexRow].join(" ")}>
      <Control.Input 
        id={props.filter.key} 
        label={props.filter.label} 
        register={(props.register) ? props.register(props.filter.key, {valueAsNumber: true}) : undefined} />
    </div>
  );
}

export default FilterNumber;
// --------------------------------