// *******************************************************
// Api Controller File - _controllers/_api.ts
// -------------------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as CONTROLLERS from '../_controllers';
import * as STATE from '../_state';
import axios from 'axios';
import * as Enums from '../_enums';
// --------------------------------

// *******************************************
// Initalisation
// -------------------------------------------
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 10000,
});
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:3000';
// instance.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
// --------------------------------

// *******************************************
// Interceptors
// -------------------------------------------
export const configInterceptors = (store: any) => {
  instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    // Look for the JWT token on the Auth Object
    const {accessToken, accessTokenExpiry, accessTokenIssue } = store.getState().AUTH;
    // Check token exipry
    if (CONTROLLERS.AUTH.validateToken({token: accessToken, tokenExpiry: accessTokenExpiry, tokenIssue: accessTokenIssue})) {
      // WE HAVE A ACTIVE JWT TOKEN
      config.headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Accept': 'application/json',
      }
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  instance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if ((error.response?.status === 403 || error.response?.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshSession = CONTROLLERS.AUTH.getRefreshToken();
      if (CONTROLLERS.AUTH.validateToken(refreshSession)) {
        const response = await store.dispatch(
          await STATE.SLICES.APP.fetchAction({
            apiURL: Enums.API_ENPOINTS.RefreshToken,
            data: {
              refresh_token: refreshSession.token,
            }
          })
        ).unwrap();
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + response.access_token;
        return instance(originalRequest);
      }
    }
    return Promise.reject(error);
  });
  
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const get = async (url: Enums.API_ENPOINTS | string) => {
  return instance.get(url)
  .then((result) => {
    return result;
  })
  .catch((error) => {
    if (error.response?.data?.message) {
      throw new Error(`ERROR: ${error.response?.data?.message}`);  
    } else if (error.message) {
      throw new Error(`ERROR: ${error.message}`);
    } else {
      throw new Error(`ERROR: ${error}`);
    }
  })
}

export const post = async (url: Enums.API_ENPOINTS | string, data: any) => {
  const postRequest = {
    url: url,
    method: 'post',
    data: data
  };

  return instance.request(postRequest)
  .then((result) => {
    return result;
  })
  .catch((error) => {
    if (error.response?.data?.message) {
      throw new Error(`ERROR: ${error.response?.data?.message}`);  
    } else if (error.message) {
      throw new Error(`ERROR: ${error.message}`);
    } else {
      throw new Error(`ERROR: ${error}`);
    }
  })
}
const API = {
  configInterceptors,
  get,
  post
}
export default API;
// --------------------------------
