// *******************************************************
// Redux Init File - state/_init.js
// -------------------------------------------------------
// This file creates the Redux Structure and Store.
// It is used in the root of the application and connects
// redux into the state of the application
// --------------------------------

// *******************************************
// Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as REDUX_PERSIST from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import REDUX_LOGGER from 'redux-logger';
import REDUCERS from './_slices';
// --------------------------------

// *******************************************************
// State Init implementation
// -------------------------------------------------------
const persistConfig = {
  key: 'root',
  blacklist: ['APP', 'AUTH'],
  storage,
}

const persistedReducer = REDUX_PERSIST.persistReducer(persistConfig, REDUCERS)

const configureAppStore = (preloadedState: any) => {
    const logger: any = process.env.NODE_ENV !== 'production' ? REDUX_LOGGER : [];
    const store = REDUX_TOOLKIT.configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [REDUX_PERSIST.FLUSH, REDUX_PERSIST.REHYDRATE, REDUX_PERSIST.PAUSE, REDUX_PERSIST.PERSIST, REDUX_PERSIST.PURGE, REDUX_PERSIST.REGISTER],
        },
      }).concat(logger),
      preloadedState,
      enhancers: []
    })
  
    if (process.env.NODE_ENV !== 'production' && module.hot) {
      module.hot.accept('./_slices', () => store.replaceReducer(persistedReducer))
    }
  
    return store;
}

export const store = configureAppStore({});
export const persistor = REDUX_PERSIST.persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// --------------------------------
