// *******************************************************
// PlayerManage - view/_private/_PlayerManage.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as yup from 'yup';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import * as CONTROLLERS from '../../_controllers';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const displayName = 'display_name';
const playerStatus = 'player_status';
const supportNotes = 'support_notes';

const PlayerManage = () => {

  const navigate = REACT_ROUTER_DOM.useNavigate();
  const dispatch = STATE.useAppDispatch();
  const [ success, setSuccess ] = React.useState(false);
  const { playerID } = REACT_ROUTER_DOM.useParams();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.PlayerUpdate));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.PlayerUpdate));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);
  const playerStatusOptions = STATE.useAppSelector(state => state.DATA.playerStatuses.options);
  const player = STATE.useAppSelector(state => state.PLAYERS.list.find(p => p.playerID === playerID ));
  
  const handleUpdatePlayer = async (form: any) => {
    try {
      await dispatch(
        await STATE.SLICES.APP.fetchAction({
          apiURL: Enums.API_ENPOINTS.PlayerUpdate,
          urlParams: [{key: ":player_id", value: playerID as string}],
          data: {
            display_name: form[displayName],
            player_status: parseInt(form[playerStatus]),
            support_notes: form[supportNotes],
          }
        })
      ).unwrap();
      // Successfully Updated the player
      setSuccess(true);
    } catch (e: any) {
      console.log(e);
    } // Errors are handled elsewhere
  }

  const schema = {
    elements: [
      CONTROLLERS.HELPER.createFormElement(displayName, Enums.Inputs.Text, "Display Name"),
      CONTROLLERS.HELPER.createFormElement(playerStatus, Enums.Inputs.Dropdown, "Player Status", {options: playerStatusOptions || []}),
      CONTROLLERS.HELPER.createFormElement(supportNotes, Enums.Inputs.Text, "Support Notes", {required: false}),
    ],
    validator: yup.object().shape({
      [displayName]: yup.string(),
      [playerStatus]: yup.string(),
      [supportNotes]: yup.string().nullable(),
    }),
    buttonLabel: 'Update Player'
  }

  const playerStatusOption = playerStatusOptions.find((o) => o.value === player.playerStatusRaw);
  const defaultFormValues = {
    [displayName]: player.stableName,
    [playerStatus]: playerStatusOption.key,
    [supportNotes]: player.supportNotes,
  }

  const _renderForm = () => {
    if (!success) {
      return (
        <>
          <UI.Control.Form schema={schema} defaultValues={defaultFormValues} onSubmit={handleUpdatePlayer} requestInProgress={isRequestInProgress} />
          <UI.Text.Error didError={didError} errorMessage={requestErrorMessage} />
        </>
      );
    }
    return null;
  }
  const _renderSuccess = () => {

    if (success) {
      return (
        <>
          <UI.Text.Paragraph>Player was updated successfully</UI.Text.Paragraph>
          <UI.Spacer size={Enums.Sizes.Small} />
          <UI.Button type={Enums.Buttons.Main} submit={() => {navigate(-1)}}>Go Back</UI.Button>
        </>
      );
    }
    return null;
  }
  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Manage</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {_renderForm()}
          {_renderSuccess()}
        </div>
      </AppWrapper>
  )
}

export default PlayerManage;
// --------------------------------
