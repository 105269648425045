// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
// type ServerHealthState = {
//   list: any[]
// }

export interface ServerHealth{
  api_health_status: string
  api_release_version: string
  last_updated: string
  caching: {
    enabled: boolean
    redis_cache: {
      online: boolean
    }
  }
  databases: {
    application_database: {
      online: boolean
    }
    betting_database: {
      online: boolean
    }
  }
  datafeed: {
    dynamic_odds: {
      online: boolean
      subscriptions_enabled: true
    }
  }
  graph_server: {
    online: boolean
  }
  server:{
    external_ip_address: string
  }
  services: {
    fiserv: {
      api_online: true
      payment_url_online: true
    }
    green_id: {
      online: boolean
    }
    loqate: {
      online: boolean
    }
  }
}

type ServerHealthState = {
  serverHealth: ServerHealth
}

export const SERVERHEALTH = REDUX_TOOLKIT.createSlice({
  name: 'SERVERHEALTH',
  initialState: {} as ServerHealthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(REDUX_TOOLKIT.isAnyOf(APP.fetchAction.fulfilled, APP.paginationAction.fulfilled), (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.ServerHealth) {
        if (!action.payload) {
          return;
        }
        state.serverHealth = action.payload as ServerHealth;
      }
    })
  }
})
export default SERVERHEALTH;
// --------------------------------