// *******************************************************
// Assets Index - _assets/index.js
// -------------------------------------------------------

// *******************************************
// Imports
// -------------------------------------------
import * as IMAGES_ from './_images';
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
export const IMAGES = IMAGES_;
// --------------------------------

