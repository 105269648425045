// *******************************************************
// Image Assets Index - _assets/_images/index.js
// -------------------------------------------------------

// *******************************************
// Imports
// -------------------------------------------
import _heroImage from './_hero_image.png';
import _logo from './_logo.png';
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
export const heroImage = _heroImage;
export const logo = _logo;
// --------------------------------