// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as AUTH from './_auth';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type PlayerState = {
  list: any[],
  statuses: any[]
}

export const PLAYERS = REDUX_TOOLKIT.createSlice({
  name: 'PLAYERS',
  initialState: {
    list: [],
    statuses: []
  } as PlayerState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(AUTH.logout, (state, action) => {
        state.list = [];
    })
    .addCase(APP.fetchAction.pending, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerSearch) { 
        state.list = [];
      }
    })
    .addMatcher(REDUX_TOOLKIT.isAnyOf(APP.fetchAction.fulfilled, APP.paginationAction.fulfilled), (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerSearch) {
        if (!action.payload?.players) {
          return;
        }
        state.list = action.payload.players.map((player: any) => {
          const playerStatus = state.statuses.find((s) => (s.Value === player.status));
          return {
            playerID: player.id,
            fullname: player.middle_names ? `${player.first_name} ${player.middle_names} ${player.last_name}` : `${player.first_name} ${player.last_name}`,
            username: player.email_address,
            dob: player.date_of_birth,
            stableName: player.display_name,
            greenID: player.greenid_verification_id,
            playerStatus: playerStatus ? playerStatus.Name : playerStatus,
            playerStatusRaw: player.status,
            supportNotes: player.support_notes
          }
        });
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerUpdate) {
        if (!action.payload?.success) {
          return;
        }
        const playerIDURLParams = action.meta.arg.urlParams?.find((urlParam) => urlParam.key === ":player_id");
        const updatedPlayerID = playerIDURLParams!.value;
        state.list = state.list.map((player) => {
          if (player.playerID === updatedPlayerID) {
            const playerStatus = state.statuses.find((s) => (s.Value === action.meta.arg.data.player_status));
            return {
              ...player,
              stableName: action.meta.arg.data.display_name,
              playerStatus: playerStatus ? playerStatus.Name : playerStatus,
              playerStatusRaw: action.meta.arg.data.player_status,
              supportNotes: action.meta.arg.data.support_notes
            }
          } else {
            return player;
          }
        });
      }
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.PlayerStatuses) {
        if (action.payload) {
          state.statuses = [].concat(action.payload);
        }
      }
    })
  }
})
export default PLAYERS;
// --------------------------------