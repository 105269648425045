// *******************************************************
// Enums Index File - enums/_sizes.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum Sizes {
  XSmall,
  Small,
  Medium,
  Large,
  XLarge,
}
export default Sizes;
// --------------------------------
