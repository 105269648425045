// *******************************************************
// 404 - view/_private/_404.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
import { LAYOUT, UI } from '../../_components';
import AppWrapper from '../_private/__AppWrapper';
import * as STATE from '../../_state';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const _404 = () => {
  const isInitialised = STATE.useAppSelector(state => state.APP.intialised);
  const { isAuthed } = STATE.useAppSelector(state => state.AUTH);

  if (!isInitialised) {
    return null;
  }

  const _renderContent = () => {
    return (
      <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
        <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>404</UI.Text.Heading>
        <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
        <UI.Text.Paragraph>Resource was not found</UI.Text.Paragraph>
      </div>
    );
  }

  if (isAuthed) {
    return (
      <AppWrapper>
        {_renderContent()}
      </AppWrapper>
    )
  }

  return (
    <LAYOUT.SingleColumn>
        <UI.HeroBanner />
        {_renderContent()}
    </LAYOUT.SingleColumn>
  );
  
}

export default _404;
// --------------------------------
