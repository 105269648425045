// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as APP from './_app';
import * as AUTH from './_auth';
import * as Enums from '../../_enums';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------

// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type LeaderboardState = {
  list: any[]
}

export const BETS = REDUX_TOOLKIT.createSlice({
  name: 'BETS',
  initialState: {
    list: []
  } as LeaderboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(AUTH.logout, (state, action) => {
        state.list = [];
    })
    .addCase(APP.fetchAction.pending, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.GameLeaderboard) { 
        state.list = [];
      }
    })
    .addCase(APP.fetchAction.fulfilled, (state, action) => {
      if (action.meta.arg.apiURL === Enums.API_ENPOINTS.GameLeaderboard) {
        if (!action.payload?.club_player_scores) {
          return;
        }
        
        state.list = action.payload.club_player_scores.map((leaderboard: any) => {
          return {
            "Rank": leaderboard.player_rank,
            "Player ID": leaderboard.player_id,
            "Stable Name": leaderboard.player_display_name,
            "Points": leaderboard.player_score,
            "Prize": (leaderboard.player_prizes_in_cents) ? `${(leaderboard.player_prizes_in_cents / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}` : "$0.00",
            "Paid out?": (leaderboard.prizes_paid) ? "Yes" : "No"
          }
        });
      }
    })
  }
})
export default BETS;
// --------------------------------