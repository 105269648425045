// *******************************************************
// Spinner - _components/_ui/_Spinner.ts
// -------------------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  width?: number,
  height?: number
}
// --------------------------------

// *******************************************
// Component Implementation
// -------------------------------------------
const Spinner = (props: Props) => {
      const { width, height } = props;
      const strokeDash = (3.14 * 55);
      return (
          <svg className={[S.Stroke.whitePrimary].join(" ")} width={width} height={height}  x="0px"  y="0px"  viewBox="0 0 80 80">
              <circle
                  className={[].join(" ")}
                  cx="40"
                  cy="40"
                  r="36"
                  fill="transparent"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeDasharray={strokeDash}>
                  <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 40 40" to="360 40 40" dur="0.8s" repeatCount="indefinite" ></animateTransform>
              </circle>
          </svg>
      );
}

Spinner.defaultProps = {
    width: 40,
    height: 40
}

export default Spinner;
// --------------------------------
