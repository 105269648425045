// *******************************************************
// Unauthorized - view/_private/_Unauthorized.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------


const Unauthorized = () => {


  const { usertypes} = STATE.useAppSelector(state => state.AUTH);
  const dispatch = STATE.useAppDispatch();
  const isBettingRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.BettingGamblingLedger));
  const isClientRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.ClientGamblingLedger));
  const didClientError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.ClientGamblingLedger));
  const didBettingError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.BettingGamblingLedger))
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);
  const [filterState, setFilterState] = React.useState({});
  const baseFilter = [
    {type: Enums.Filters.Date, key: 'datetime_from', label: "Transactions Date From*"},
    {type: Enums.Filters.Date, key: 'datetime_to', label: "Transactions Date To*"},
  ];
    const clientFilter = [
        {type: Enums.Filters.Date, key: 'datetime_to', label: "Date To*"},
    ];
  let PlayerTransactionsFilter;
  let ClientLedgerFilter;
  PlayerTransactionsFilter = baseFilter;
  ClientLedgerFilter = clientFilter;

  const generateClientCSVFile = async (filters:any) => {

    const response = await dispatch(
      await STATE.SLICES.APP.fetchAction({
  
        apiURL: Enums.API_ENPOINTS.ClientGamblingLedger,
          data: {
              ...filterState,
          }
  
      })
  
    ).unwrap();
    downloadCSV(response, 'export-client-ledger.csv')
  
  }

  const generateBettingCSVFile = async (filters: any) => {

    const response = await dispatch(
      await STATE.SLICES.APP.fetchAction({
  
        apiURL: Enums.API_ENPOINTS.BettingGamblingLedger,
        data: {
          ...filterState,
        }
  
      })
  
    ).unwrap();
    downloadCSV(response, 'export-betting-ledger.csv')
  
  }
  
  function downloadCSV(csvStr:any, name:string) {
  
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = name;
    hiddenElement.click();
  }
    const _renderSubmitButton = (inProgress: boolean, submitFunction: () => Promise<void>) => {
        if (inProgress) {
            return (
                <div className={[S.Background.bluePrimary_Hover, S.Fonts.Colour_whiteTertiary, S.Border.Width_1, S.Border.Colour_bluePrimary, S.Border.Radius_4, S.Layout.flexCol, S.Layout.bothAxisCenter, S.Padding._4].join(" ")}>
                    <UI.Spinner />
                </div>
            );
        } else {
            return (
                <UI.Button submit={submitFunction} type={Enums.Buttons.Alternate}> Generate CSV</UI.Button>
            )
        }
    }

  const _renderForm = (PlayerTransactionsFilter: any) => {
    if (usertypes.includes(Enums.UserTypes.SUPER_ADMINISTRATOR)) {
      return (
        <>
          <div className={[S.Layout.flexRow, S.Border.Radius_20,S.Height.Min_100, S.Padding.Left_50, S.Padding.Top_30, S.Padding.Bottom_30, S.Margin.Top_50,S.Background.whitePrimary].join(" ")}>
              <div className={[S.Layout.flexCol].join(" ")}>
                  <div className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_24, S.Fonts.Colour_bluePrimary].join(" ")}>Betting Ledger</div>
                  <UI.Spacer size={Enums.Sizes.Small}/>
                  <UI.Text.ParagraphSmall>To generate the betting ledger, select date ranges and click search before generating the csv file.</UI.Text.ParagraphSmall>
                  <UI.Text.ParagraphSmall>The resulting report will include all records up to and including 23:59 on the chosen date, which is a UTC timezone datetime.</UI.Text.ParagraphSmall>
                  <UI.Spacer size={Enums.Sizes.Small}/>
                  <UI.Filter filters={PlayerTransactionsFilter} submit={setFilterState}/>
                  <UI.Spacer size={Enums.Sizes.Small}/>
                  <div className={[S.Layout.flexCol, S.Width._40].join(" ")}>
                      {_renderSubmitButton(isBettingRequestInProgress, () => generateBettingCSVFile(filterState))}
                  </div>
                  <UI.Text.Error didError={didBettingError} errorMessage={requestErrorMessage} />
              </div>
          </div>
        </>
      );
    }
    return null;
  }

    const _renderClientForm = (ClientLedgerFilter: any) => {
        if (usertypes.includes(Enums.UserTypes.SUPER_ADMINISTRATOR)) {
            return (
                <>
                    <div className={[S.Layout.flexRow, S.Border.Radius_20,S.Height.Min_100, S.Padding.Left_50, S.Padding.Top_30, S.Padding.Bottom_30, S.Margin.Top_50,S.Background.whitePrimary].join(" ")}>
                        <div className={[S.Layout.flexCol].join(" ")}>
                            <div className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_24, S.Fonts.Colour_bluePrimary].join(" ")}>Client Ledger</div>
                            <UI.Spacer size={Enums.Sizes.Small}/>
                            <UI.Text.ParagraphSmall>To generate the client ledger, select the Date (mandatory) you wish to search until.</UI.Text.ParagraphSmall>
                            <UI.Text.ParagraphSmall>The resulting report will include all records up to and including 23:59 on the chosen date, which is a UTC timezone datetime.</UI.Text.ParagraphSmall>
                            <UI.Text.ParagraphSmall>This will only include clients joined before this date, and their balance at the time.</UI.Text.ParagraphSmall>
                            <UI.Spacer size={Enums.Sizes.Small}/>
                            <UI.Filter filters={ClientLedgerFilter} submit={setFilterState}/>
                            <UI.Spacer size={Enums.Sizes.Small}/>
                            <div className={[S.Layout.flexCol, S.Width._40].join(" ")}>
                                {_renderSubmitButton(isClientRequestInProgress, () => generateClientCSVFile(filterState))}
                            </div>
                            <UI.Text.Error didError={didClientError} errorMessage={requestErrorMessage} />
                        </div>
                    </div>
                </>
            );
        }
        return null;
    }

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Dashboard</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}/>
          <UI.Text.Paragraph>Welcome to the Group 1 Admin Console.</UI.Text.Paragraph>
          {_renderForm(PlayerTransactionsFilter)}
            {_renderClientForm(ClientLedgerFilter)}
        </div>
      </AppWrapper>
  )
}

export default Unauthorized;
// --------------------------------
