// *******************************************************
// Application Reducer
// -------------------------------------------------------
// Contains ther overachine application start point
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import * as REDUX_TOOLKIT from '@reduxjs/toolkit';
import * as CONTROLLERS from '../../_controllers';
import * as Enums from '../../_enums';
import * as PAGINATION from './_pagination';
// --------------------------------

// *******************************************
// Actions Implementation
// -------------------------------------------
type fetchRequest = {
  apiURL: Enums.API_ENPOINTS,
  data?: any,
  urlParams?: urlParams[]
  method?: 'post' | 'get' | null,
  pagination?: {
    direction: 'next' | 'previous'
  }
}

type urlParams = {
  key: string,
  value: string
}

export const fetchAction = REDUX_TOOLKIT.createAsyncThunk<any, fetchRequest, {state: { APP: AppState }}> (
  'fetchAction',
  async (params: fetchRequest, thunkAPI: any) => {
    
    // This is saying, dont fire the request again if any request is in flight!
    const { currentRequestId, requestStatus } = thunkAPI.getState().APP;
    const pagination = thunkAPI.getState().PAGINATION;
    if (requestStatus !== Enums.States.Pending || thunkAPI.requestId !== currentRequestId) {
      return
    }

    // Make api call
    let urlString = params.apiURL as string;
    if (params.urlParams) {
      params.urlParams.forEach((urlParam: urlParams) => {
        urlString = urlString.replace(urlParam.key, urlParam.value);
      })
    }
    if (params.method === 'get') {
      const response = await CONTROLLERS.API.get(urlString);
      return response.data;
    } else {
      const requestPagination = pagination[params.apiURL] || PAGINATION.paginationDefault;
      const requestBody = (params.pagination) ? {
        page_number: params.pagination.direction === 'next' ? requestPagination.pageNumber + 1 : requestPagination.pageNumber - 1,
        page_size: requestPagination.pageSize,
        sort_column: requestPagination.sortColumn,
        sort_direction: requestPagination.sortDirection,
        ...params.data
      } : params.data;
      const response = await CONTROLLERS.API.post(urlString, requestBody);
      return response.data
    } 
  }
)

export const paginationAction = REDUX_TOOLKIT.createAsyncThunk<any, fetchRequest, {state: { APP: AppState }}> (
  'paginationAction',
  async (params: fetchRequest, thunkAPI: any) => {

    // This is saying, dont fire the request again if any request is in flight!
    const { currentRequestId, requestStatus } = thunkAPI.getState().APP;
    const pagination = thunkAPI.getState().PAGINATION;
    if (requestStatus !== Enums.States.Pending || thunkAPI.requestId !== currentRequestId) {
      return
    }

    // Make api call
    let urlString = params.apiURL as string;
    if (params.urlParams) {
      params.urlParams.forEach((urlParam: urlParams) => {
        urlString = urlString.replace(urlParam.key, urlParam.value);
      })
    } 
    const requestPagination = pagination[params.apiURL];
    const nextPageNumber = params.pagination!.direction === 'next' ? requestPagination.pageNumber + 1 : requestPagination.pageNumber - 1;
    const requestBody = {
      page_number: nextPageNumber,
      page_size: requestPagination.pageSize,
      sort_column: requestPagination.sortColumn,
      sort_direction: requestPagination.sortDirection,
      ...requestPagination.lastRequestBody
    };

    const response = await CONTROLLERS.API.post(urlString, requestBody);
    return response.data
  }
)
// --------------------------------

// *******************************************
// Reducer Implementation
// -------------------------------------------
type AppState = {
  intialised: boolean,
  requestStatus: Enums.States,
  requestsInProgress: Enums.API_ENPOINTS[],
  requestErrorKeys: Enums.API_ENPOINTS[],
  requestErrorMessage: string | null | undefined,
  currentRequestId: string | null | undefined
}

export const APP = REDUX_TOOLKIT.createSlice({
  name: 'APP',
  initialState: {
    intialised: false,
    requestStatus: Enums.States.Idle,
    requestsInProgress: [],
    requestErrorKeys: [],
    requestErrorMessage: null,
    currentRequestId: undefined
  } as AppState,
  reducers: {
    initialise: (state) => {
      state.intialised = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(REDUX_TOOLKIT.isAnyOf(fetchAction.pending, paginationAction.pending), (state, action) => {
        state.requestStatus = Enums.States.Pending;
        state.requestsInProgress = state.requestsInProgress.concat([action.meta.arg.apiURL]);
        state.requestErrorKeys = [];
        state.requestErrorMessage = null;
        state.currentRequestId = action.meta.requestId;
      })
      .addMatcher(REDUX_TOOLKIT.isAnyOf(fetchAction.rejected, paginationAction.rejected), (state, action) => {
        state.requestStatus = Enums.States.Rejected;
        state.requestsInProgress = state.requestsInProgress.filter((apiURL) => apiURL !== action.meta.arg.apiURL);
        state.requestErrorKeys = state.requestErrorKeys.concat([action.meta.arg.apiURL]);
        state.requestErrorMessage = action.error.message;
        state.currentRequestId = undefined;
      })
      .addMatcher(REDUX_TOOLKIT.isAnyOf(fetchAction.fulfilled, paginationAction.fulfilled), (state, action) => {
        state.requestStatus = Enums.States.Fulfilled;
        state.requestsInProgress = state.requestsInProgress.filter((apiURL) => apiURL !== action.meta.arg.apiURL);
        state.currentRequestId = undefined
      })
  }
})

export const { initialise } = APP.actions;
export default APP;
// --------------------------------