// *******************************************************
// Text UI Index - components/_ui/_Text/index.js
// -------------------------------------------------------
// This is the index file for all generic UI components.
// -------------------------------------------

// *******************************************
// Imports
// -------------------------------------------
import _Error from './_Error';
import _Heading from './_Heading';
import _Paragraph from './_Paragraph';
import _ParagraphSmall from './_Paragraph_small';
import _ListItem from "./_ListItem";
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
export const Error = _Error;
export const Heading = _Heading;
export const ListItem = _ListItem;
export const Paragraph = _Paragraph;
export const ParagraphSmall = _ParagraphSmall;
// --------------------------------
