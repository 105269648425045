// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------

export const Boolean = [ 
  { key: 'true', value: true, label: "Yes" },
  { key: 'false', value: false, label: "No" },
];

export default Boolean;
// --------------------------------
