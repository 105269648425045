// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum TableTypes {
  Array,
  Object,
}
export default TableTypes;
// --------------------------------
