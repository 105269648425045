// *******************************************************
// Enums Index File - enums/_filters.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum Filters {
  Text,
  Number,
  List,
  Date,
  DateRange,
  SortCol,
  SortDirection

}
export default Filters;
// --------------------------------
