// *******************************************************
// Date Component - _components/_ui/_Date.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as ReactSpring from 'react-spring';
import * as ReactHookForm from 'react-hook-form';
import * as S from '../../../../_styles';
import InputStyles from '../_Input/_input.module.scss';
import * as Luxon from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  id?: string
  label?: string
  children?: React.ReactNode
  password?: boolean
  icon?: React.ReactNode
  register?: ReactHookForm.UseFormRegisterReturn<string> //comes directly from React Hook Form
  setValue?: any;
  errorMessage?: any;
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Date = (props: Props) => {

  const [localValue, setLocalValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const dateContainerStyles = ReactSpring.useSpring({ height: open ? 'initial' : 0, borderWidth: open ? 1 : 0 });

  const _convertTimestampToHumanReadable = (dateISO: string) => {
    const date = Luxon.DateTime.fromISO(dateISO);
    return date.toLocaleString(Luxon.DateTime.DATE_SHORT);
  };

  const _stripTimezone = (date: Luxon.DateTime) => {
    const timeInUTC = Luxon.DateTime.utc(date.year, date.month, date.day);
    return timeInUTC.toISO();
  }

  const ReactSpringAnimatedDiv = ReactSpring.animated.div;
  return (
    <div className={S.Layout.relative}>
      <div className={[S.Layout.relative, InputStyles.inputContainer, S.Fonts.pointer].join(" ")} onClick={() => {
        setOpen(!open)
      }}>
          <input 
            className={[S.Fonts.pointer, localValue ? InputStyles.hasText : ""].join(" ")} 
            style={{height: 60}}
            id={props.id}
            value={(localValue) ? _convertTimestampToHumanReadable(localValue) :  ""}
            disabled
            required />
        <label
            htmlFor={props.id} 
            className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_14, S.Fonts.Colour_whitePrimary].join(" ")}>{props.label}</label>
      </div>
      <ReactSpringAnimatedDiv className={[S.Layout.absoluteTopLeft, S.Width._100, S.Background.whiteTertiary, S.Scroll.Y].join(" ")} style={{...dateContainerStyles, width: "initial", top: 60, zIndex: 9999}}>
        <LocalizationProvider adapterLocale={'utc'} dateAdapter={AdapterLuxon}>
            <StaticDatePicker
              value={localValue}
              onChange={(newValue: any) => {
                const utcValue = _stripTimezone(newValue);
                setLocalValue(utcValue);
                setOpen(false);
                if (props.setValue && props.register) {
                  props.setValue(props.register.name, utcValue);
                }
              }}
              disableOpenPicker={true}
              showToolbar={false}
              renderInput={(params) => {
                return (
                  <input 
                    className={[S.Fonts.pointer, localValue ? InputStyles.hasText : ""].join(" ")} 
                    style={{height: 60}}
                    id={props.id}
                    value={localValue || ""}
                    required />
                );
              }}
            />
        </LocalizationProvider>
      </ReactSpringAnimatedDiv>
    </div>
  );
}

export default Date;
// --------------------------------