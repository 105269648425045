// *******************************************************
// PlayerGamedayLedger - view/_private/_PlayerGamedayLedger.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const PlayerGamedayLedger = () => {

  const dispatch = STATE.useAppDispatch();
  const { playerID, racedayID } = REACT_ROUTER_DOM.useParams();
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.PlayerPointsLedger));

  React.useEffect(() => {
    try {
      if (!playerID || !racedayID) {
        throw new Error("PlayerID and RacedayID are required fields for this page");
      }
      const getGamedayLedgerTransactions = async () => {
        await dispatch(
          await STATE.SLICES.APP.fetchAction({
            apiURL: Enums.API_ENPOINTS.PlayerPointsLedger,
            urlParams: [{key: ":player_id", value: playerID as string}],
            data: {
              "raceday_id": parseInt(racedayID),
            }
          })
        ).unwrap();
      }
      getGamedayLedgerTransactions();
    } catch (e: any) {} 
  }, [dispatch, playerID, racedayID]);

  const ledgerlist = STATE.useAppSelector(state => state.LEDGER.list);
  const ledgerTableList = ["Timestamp", "Race Number", "Race Level", "Runner Name", "Final Position", "Points Type", "Points", "Streak Count", "Threat Count"];

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Transactions</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Text.Paragraph>Showing Gameday Ledger for {playerID} on Raceday {racedayID}</UI.Text.Paragraph>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          {isRequestInProgress ? <UI.SkeletonTable columns={ledgerTableList} /> : null }
          {!isRequestInProgress && ledgerlist?.length > 0 ? <UI.Table columns={ledgerTableList} rows={{data: ledgerlist, dataType: Enums.TableTypes.Object}} /> : null }
          {!isRequestInProgress && ledgerlist?.length === 0 ?  <UI.Table columns={ledgerTableList} rows={{data: []}} /> : null}
        </div>
      </AppWrapper>
  )
}

export default PlayerGamedayLedger;
// --------------------------------
