// *******************************************************
// Hero Component - _components/_ui/_HeroBanner.js
// -------------------------------------------------------
// This is the hero banner for all public pages of the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as ASSETS from '../../_assets';
import * as S from '../../_styles';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  children?: React.ReactNode
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const HeroBanner = (props: Props) => {  
  
  // const heroImageStyles = {
  //   backgroundImage: `url(${ASSETS.IMAGES.heroImage})`,
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: '100% 100%',
  //   backgroundColor: "black",
  //   opacity: 0.5,
  // }

  const logoImageStyles = {
    backgroundImage: `url(${ASSETS.IMAGES.logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  }

  return (
    <div className={[S.Layout.Width_100, S.Layout.flexCol, S.Layout.bothAxisCenter, S.Background.defaultBG].join(" ")} style={{height: 400}}>
        {/* <div style={{...heroImageStyles, width: 400, height: 400}}></div> */}
        <div className={[S.Layout.absoluteTop, S.Layout.flexCol, S.Layout.bothAxisCenter, S.Layout.textCenter].join(" ")} style={{height: 400}}>
          <div style={{...logoImageStyles, width: 200, height: 200}}></div>
        </div>
    </div>
  );
}

export default HeroBanner;
// --------------------------------