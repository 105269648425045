// *******************************************************
// Enums Index File - enums/_buttons.js
// -------------------------------------------------------

// *******************************************
// Implementation
// -------------------------------------------
const enum Buttons {
  Main,
  Alternate,
  Outline,
  Disabled
}
export default Buttons;
// --------------------------------
