// *******************************************************
// Private Pages Index - _pages/_private/index.js
// -------------------------------------------------------
// This is the index file for all Private pages in the application
// -------------------------------------------

// *******************************************
// Imports
// -------------------------------------------
import __AppWrapper from './__AppWrapper';
import __ProtectedRoute from './__ProtectedRoute';
import _Dashboard from './_Dashboard';
import _GameLeaderboard from './_GameLeaderboard';
import _GameManage from './_GameManage';
import _GameSearch from './_GameSearch';
import _PlayerBets from './_PlayerBets';
import _PlayerDetail from './_PlayerDetail';
import _PlayerGamedayLedger from './_PlayerGamedayLedger';
import _PlayerGames from './_PlayerGames';
import _PlayerManage from './_PlayerManage';
import _PlayerSearch from './_PlayerSearch';
import _PlayerTransactions from './_PlayerTransactions';
import _RacedayCreate from './_RacedayCreate';
import _RacedayCreateSuccess from './_RacedayCreateSuccess';
import _ServerHealth from "./_ServerHealth";
// --------------------------------

// *******************************************
// Exports
// -------------------------------------------
export const AppWrapper = __AppWrapper;
export const ProtectedRoute = __ProtectedRoute;
export const Dashboard = _Dashboard;
export const GameLeaderboard = _GameLeaderboard;
export const GameManage = _GameManage;
export const GameSearch = _GameSearch;
export const PlayerBets = _PlayerBets;
export const PlayerDetail = _PlayerDetail;
export const PlayerGamedayLedger = _PlayerGamedayLedger;
export const PlayerGames = _PlayerGames;
export const PlayerManage = _PlayerManage;
export const PlayerSearch = _PlayerSearch;
export const PlayerTransactions = _PlayerTransactions;
export const RacedayCreate = _RacedayCreate;
export const RacedayCreateSuccess = _RacedayCreateSuccess;
export const ServerHealth = _ServerHealth;
// --------------------------------

