// *******************************************************
// Form Component - _components/_ui/_Form.js
// -------------------------------------------------------
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as ReactHookForm from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as Enums from '../../../_enums';
import * as S from '../../../_styles';
import { UI } from '../../index';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  schema: Schema,
  onSubmit: Function
  requestInProgress?: boolean
  defaultValues?: any
}

type Schema = {
  elements: Array<Element>,
  validator: Yup.ObjectSchema<any>
  buttonLabel: string
}

type Element = {
  key: string,
  type: Enums.Inputs,
  label: string,
  dynamicOptions?: any
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Form = (props: Props) => {
  const { register, setValue, handleSubmit, formState: { errors } } = ReactHookForm.useForm({
    defaultValues: props.defaultValues || {},
    resolver: yupResolver(props.schema.validator)
  });

  const _convertSchemeToElement = (element: any, index: number, lastIndex: number) => {
    const spacer = (index !== lastIndex) ? <UI.Spacer size={Enums.Sizes.Small} /> : null;
    switch (element.type) {
      case Enums.Inputs.Text:
        return (
          <React.Fragment key={`form-input-${element.key}-${index}`}>
            <UI.Control.Input id={element.key} label={element.label} register={register(element.key)} defaultValue={props.defaultValues ? props.defaultValues[element.key] : null} required={element.dynamicProps?.required} errorMessage={errors[element.key]?.message} />
            {spacer}
          </React.Fragment>
        )
        case Enums.Inputs.Password:
        return (
          <React.Fragment key={`form-input-${element.key}-${index}`}>
            <UI.Control.Input id={element.key} label={element.label} register={register(element.key)} password errorMessage={errors[element.key]?.message} />
            {spacer}
          </React.Fragment>
        )
        case Enums.Inputs.Date:
        return (
          <React.Fragment key={`form-input-${element.key}-${index}`}>
            <UI.Control.Date id={element.key} label={element.label} register={register(element.key)} setValue={setValue} errorMessage={errors[element.key]?.message} />
            {spacer}
          </React.Fragment>
        )
        case Enums.Inputs.Dropdown:
        return (
          <React.Fragment key={`form-input-${element.key}-${index}`}>
            <UI.Control.Dropdown id={element.key} label={element.label} defaultValue={props.defaultValues ? props.defaultValues[element.key] : null} register={register(element.key)} onSelect={(option:any) => {setValue(element.key, option.value)}} options={element.dynamicProps?.options} errorMessage={errors[element.key]?.message} />
            {spacer}
          </React.Fragment>
        )
      default:
        throw new Error(`ERROR: Element type enum not found: ${element.key} ${element.type}`);
    }
  }

  let formElements = props.schema.elements.map((e: any, i: any) => _convertSchemeToElement(e, i, props.schema.elements.length));

  const _renderSubmitButton = () => {
    if (props.requestInProgress) {
      return (
        <div className={[S.Background.redPrimary_Hover, S.Fonts.Colour_whiteTertiary, S.Border.Radius_4, S.Layout.Width_100, S.Layout.flexCol, S.Layout.bothAxisCenter, S.Padding._5].join(" ")} >
          <UI.Spinner />
        </div>
      );
    } else {
      return (
        <input className={[S.Background.redPrimary_Hover, S.Fonts.Colour_whiteTertiary, S.Border.Radius_4, S.Layout.Width_100, S.Layout.flexCol, S.Layout.bothAxisCenter, S.Padding._15].join(" ")} type="submit" value={props.schema.buttonLabel} />
      )
    }
  }

  return (
      <form onSubmit={handleSubmit((form: any) => props.onSubmit(form))}>
        {formElements}
        {_renderSubmitButton()}
      </form>
  );
}

export default Form;
// --------------------------------