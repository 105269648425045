// *******************************************************
// Input Component - _components/_ui/_Input.js
// -------------------------------------------------------
// A generic Spacer component
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import { UseFormRegisterReturn } from "react-hook-form";
import * as S from '../../../../_styles';
import InputStyles from './_input.module.scss';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  id: string
  label: string
  children?: React.ReactNode
  password?: boolean,
  number?: boolean,
  required?: boolean,
  register?: UseFormRegisterReturn<string> //comes directly from React Hook Form
  errorMessage?: any;
  defaultValue?: any
}
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------
export const Input = (props: Props) => {
  
  const [hasTextInput, setHasTextInput] = React.useState(props.defaultValue ? true : false);
  
  const handleBlur = (event: React.FocusEvent) => {
    const element = event.target as HTMLInputElement;
    if (element.value) {
      setHasTextInput(true);
    } else {
      setHasTextInput(false);
    }
    if (props.register?.onBlur) {
      props.register.onBlur(event);
    }
  };


  let type = 'text';
  if (props.password) {
    type = 'password';
  } else if (props.number) {
    type = 'number';
  }

  return (
    <>
    <div className={[S.Layout.relative, InputStyles.inputContainer].join(" ")}>
      <input 
        autoComplete="current-password"
        className={[hasTextInput ? InputStyles.hasText : ""].join(" ")} 
        type={type}
        style={{height: 60}}
        id={props.id}
        required={props.required === undefined ? true : props.required}
        {...props.register}
        onBlur={handleBlur}  />
      <label 
        htmlFor={props.id} 
        className={[S.Fonts.Montserrat_Bold, S.Fonts.Size_14, S.Fonts.Colour_whitePrimary].join(" ")}>{props.label}</label>
        
    </div>
      {props.errorMessage ? <p className={[S.Padding.Top_5, S.Padding.Bottom_5, S.Fonts.Montserrat_Bold, S.Fonts.Size_14, S.Fonts.Colour_redPrimary].join(" ")}>{props.errorMessage}</p> : null}
    </>
  );
}

export default Input;
// --------------------------------