// *******************************************************
// Sidebar Navigtaor Component - _components/_structure/_SidebarNavigator.js
// -------------------------------------------------------
// This structural component lays out content with a sidebar navigator, it
// accepts a sidebar component as a 
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React, { Fragment } from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import * as Enums from '../../_enums';
import * as UI from '../_ui';
// --------------------------------

// *******************************************
// Props Declaration
// -------------------------------------------
type Props = {
  minHeight?: string,
  sidebarWidth?: string,
  children?: React.ReactNode
  navigationLinks?: NavItem[]
}
export type NavItem = {
  type: Enums.NavType,
  label: string,
  link?: Enums.Routes,
  onClick?: () => {},
}
// --------------------------------

// *******************************************
// Component Implementation
// -------------------------------------------
export const SidebarNavigation = (props: Props) => {

  const sidebarWidth = props.sidebarWidth || "250px";
  const contentWidth = `calc(100vw - ${sidebarWidth})`;

  const _renderSidebar = () => {
    return (
      <div className={[S.Background.whiteSecondary, S.Layout.flexCol, S.Padding._50, S.Padding.Left_50].join(" ")} style={{width: sidebarWidth, minHeight: "100%"}}>
        {props.navigationLinks?.map((navItem, index) => {
          const navStyles = [S.Fonts.Colour_bluePrimary_Hover, S.Fonts.Montserrat_Bold, S.Fonts.Size_16].join(" ")
          if (navItem.type === Enums.NavType.Link) {
            return (
              <Fragment key={`nav-item-${index}`}>
                <REACT_ROUTER_DOM.Link to={navItem.link!}>
                    <div className={navStyles}>{navItem.label}</div>    
                </REACT_ROUTER_DOM.Link>
                <UI.Spacer size={Enums.Sizes.Small} />
              </Fragment>
            );
          }
          if (navItem.type === Enums.NavType.Function) {
            return (
              <Fragment key={`nav-item-${index}`}>
                <div className={navStyles} onClick={navItem.onClick}>{navItem.label}</div>    
                <UI.Spacer size={Enums.Sizes.Small} />
              </Fragment>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return (
    <div className={[S.Width._100, S.Layout.flexRow].join(" ")} style={{minHeight: props.minHeight || "100vh"}}>
      {_renderSidebar()}
      <div style={{width: contentWidth, minHeight: props.minHeight}}>
        {props.children}
      </div>
    </div>
  );
}

export default SidebarNavigation;
// --------------------------------