// *******************************************************
// PlayerDetail - view/_private/_PlayerDetail.js
// -------------------------------------------------------
// This is the search for players page of the application
// -------------------------------------------

// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as REACT_ROUTER_DOM from 'react-router-dom';
import * as S from '../../_styles';
import { UI } from '../../_components';
import AppWrapper from './__AppWrapper';
import * as Enums from '../../_enums';
import * as STATE from '../../_state';
import * as CONTROLLERS from "../../_controllers";
import * as yup from "yup";
// --------------------------------

// *******************************************
// Implementation
// -------------------------------------------

const email = 'email';
const show = true;
const PlayerDetail = () => {

  const navigate = REACT_ROUTER_DOM.useNavigate();
  const { playerID } = REACT_ROUTER_DOM.useParams();
  const dispatch = STATE.useAppDispatch();
  const [ success, setSuccess ] = React.useState(false);
  const player = STATE.useAppSelector(state => state.PLAYERS.list.find(p => p.playerID === playerID ));
  const isRequestInProgress = STATE.useAppSelector(state => state.APP.requestsInProgress.includes(Enums.API_ENPOINTS.PlayerActivityStatement));
  const didError = STATE.useAppSelector(state => state.APP.requestErrorKeys.includes(Enums.API_ENPOINTS.PlayerActivityStatement));
  const requestErrorMessage = STATE.useAppSelector(state => state.APP.requestErrorMessage);


  const handleGenerateActivityStatements = async (form: any) => {
    try {
      await dispatch(
          await STATE.SLICES.APP.fetchAction({
            apiURL: Enums.API_ENPOINTS.PlayerActivityStatement,
            urlParams: [{key: ":player_id", value: playerID as string}],
            data: {
              email: form[email],
              player_id: playerID as string,
            }
          })
      ).unwrap();
      // Successfully Updated the player
      setSuccess(true);
    } catch (e: any) {
      console.log(e);
    } // Errors are handled elsewhere
  }

  const schema = {
    elements: [
      CONTROLLERS.HELPER.createFormElement(email, Enums.Inputs.Text, "Email Address"),
    ],
    validator: yup.object().shape({
      [email]: yup.string(),
    }),
    buttonLabel: 'Generate Latest Activity Statement'
  }

  const defaultFormValues = {
    [email]: player.username,
  }
  // not visible to front end yet
  const _renderForm = () => {
    if (!success) {
      return (
          <>
            <UI.Control.Form schema={schema} defaultValues={defaultFormValues} onSubmit={handleGenerateActivityStatements} requestInProgress={isRequestInProgress} />
            <UI.Text.Error didError={didError} errorMessage={requestErrorMessage} />
          </>
      );
    }
    return null;
  }
  const _renderSuccess = () => {

    if (success) {
      return (
          <>
            <UI.Text.Paragraph>Activity statement was successfully generated and sent</UI.Text.Paragraph>
            <UI.Spacer size={Enums.Sizes.Small} />
            <UI.Button type={Enums.Buttons.Main} submit={() => {navigate(-1)}}>Go Back</UI.Button>
          </>
      );
    }
    return null;
  }

  if (!player) {
    return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Detail</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Medium}></UI.Spacer>
          <UI.Text.Paragraph>Player with id {playerID} not found.</UI.Text.Paragraph>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <div className={[S.Layout.flexRow].join(" ")}>
            <UI.Button submit={() => { navigate(-1) }} type={Enums.Buttons.Alternate}>Back</UI.Button>
          </div>
        </div>
      </AppWrapper>
    )
  }

  return (
      <AppWrapper>
        <div className={[S.Width._100, S.Height.Min_100, S.Padding._50, S.Background.whitePrimary].join(" ")}>
          <UI.Text.Heading fontColour={S.Fonts.Colour_bluePrimary}>Player Detail</UI.Text.Heading>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <UI.Text.Paragraph>Player Name: {player.fullname}</UI.Text.Paragraph>
          <UI.Text.Paragraph>Player Username: {player.username}</UI.Text.Paragraph>
          <UI.Text.Paragraph>Player Status: {player.playerStatus}</UI.Text.Paragraph>
          <UI.Text.Paragraph>Player DOB: {player.dob}</UI.Text.Paragraph>
          <UI.Text.Paragraph>Player Green ID: {player.greenID}</UI.Text.Paragraph>
          <UI.Text.Paragraph>Player Stable Name: {player.stableName}</UI.Text.Paragraph>
          <UI.Text.Paragraph>Player Support Notes: {player.supportNotes}</UI.Text.Paragraph>
          <UI.Spacer size={Enums.Sizes.Small}></UI.Spacer>
          <div style={{maxWidth: 400}}>
            <UI.Button 
              submit={() => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.PlayerManage, {playerID: player.playerID})) }}
              type={Enums.Buttons.Alternate}>
              Manage Player
            </UI.Button>
            <UI.Spacer size={Enums.Sizes.XSmall}></UI.Spacer>
            <UI.Button 
              submit={() => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.PlayerGames, {playerID: player.playerID})) }}
              type={Enums.Buttons.Alternate}>
              View Games
            </UI.Button>
            <UI.Spacer size={Enums.Sizes.XSmall}></UI.Spacer>
            <UI.Button 
              submit={() => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.PlayerBetsPlayerID, {playerID: player.playerID})) }} 
              type={Enums.Buttons.Alternate}>
              View Bets
            </UI.Button>
            <UI.Spacer size={Enums.Sizes.XSmall}></UI.Spacer>
            <UI.Button 
              submit={() => { navigate(Enums.ROUTE_BUILDER(Enums.Routes.PlayerTransactionsPlayerID, {playerID: player.playerID})) }} 
              type={Enums.Buttons.Alternate}>
              View Transactions
            </UI.Button>
            <UI.Spacer size={Enums.Sizes.Large}></UI.Spacer>
            {show ? _renderForm() : null }
            {_renderSuccess()}
          </div>
        </div>
      </AppWrapper>
  )
}

export default PlayerDetail;
// --------------------------------
